import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { PasswordForm } from '../../components/PasswordForm';
import { resetPassword } from '../../api/http-client/queries/accounts';
import { Loader } from '../../components/Loader/Loader';
import { notifyMessages } from '../../utilities/constants';
import { useTranslates } from '../../providers/translates';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch, useRedirectAndScroll } from 'hooks';

import styles from './resetPassword.module.scss';

export const ResetPassword = () => {
  const { onlyRedirect } = useRedirectAndScroll({});
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();

  const changePassword = (password: string) => {
    setLoading(true);
    resetPassword(params.get('userId'), params.get('token'), password).finally(() => {
      dispatch(
        setOpen(translates?.notifyMessages?.passwordChanged || notifyMessages.passwordChanged)
      );
      onlyRedirect('/login');
      setLoading(false);
    });
  };

  return (
    <div className={styles.reset_container}>
      <div className={`center ${styles.reset_box}`}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card
            sx={{ px: { xs: 2, sm: 3, md: 4, lg: 6 }, py: { xs: 4, sm: 6 }, width: { xs: '100%' } }}
          >
            <span className="defaultTitle">
              {translates?.resetPassword?.resetPasswordTitle || 'Create a new password'}
            </span>
            <Box sx={{ mt: 4 }}>
              <PasswordForm changePassword={changePassword} />
            </Box>
          </Card>

          <Loader loading={loading} isWhite={false} />
        </Box>
      </div>
    </div>
  );
};
