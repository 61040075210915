import { StickyShareButtons } from 'sharethis-reactjs';
import { useEffect, useRef } from 'react';
import { openLinkInNewWindow } from 'utilities';
import chainLinkIcon from '../../assets/icons/chain-link.svg';
import { useAppSelector } from 'hooks';

type Props = {};

const CustomShareButtons: React.FC<Props> = ({}) => {
  const editorData = useAppSelector((state) => state.quilt.editableQuilt);
  const stickyShareButtonsContainerRef = useRef<HTMLDivElement | null>(null);
  const currentPageUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentPageUrl);
  };

  const sendMail = () => {
    const emailSubject = 'Someone has shared a memorial quilt square with you';
    const emailBody = `A virtual quilt square has been made in memory of ${editorData.firstName} ${editorData.lastName} on the Canadian Healing Quilt. You are invited to view this memorial at: ${window.location.href}`;
    window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  };

  // logic for adding custom instagram and copy link (change mailru and vk)
  useEffect(() => {
    if (!stickyShareButtonsContainerRef.current) return;

    const stickyShareButtonRef = stickyShareButtonsContainerRef.current?.firstElementChild || null;

    setTimeout(() => {
      const mailruShareButtonOrig = stickyShareButtonRef?.childNodes.item(2) as HTMLImageElement;
      const mailruShareButton = mailruShareButtonOrig?.cloneNode(true) as HTMLImageElement;
      const mailruShareButtonImg = mailruShareButton?.firstElementChild as HTMLImageElement;

      const vkShareButton = stickyShareButtonRef?.childNodes.item(0) as HTMLImageElement;
      const copyPageUrlButton = vkShareButton?.cloneNode(true) as HTMLImageElement;
      const copyPageUrlButtonImg = copyPageUrlButton?.firstElementChild as HTMLImageElement;
      const copyPageUrlButtonText = copyPageUrlButton?.lastElementChild as HTMLImageElement;

      const emailButtonOrig = stickyShareButtonRef?.childNodes.item(5) as HTMLImageElement;
      const newEmailButton = emailButtonOrig?.cloneNode(true) as HTMLImageElement;

      if (
        !mailruShareButton ||
        !mailruShareButtonImg ||
        !vkShareButton ||
        !copyPageUrlButton ||
        !newEmailButton
      )
        return;

      // change style
      mailruShareButton.style.backgroundColor = '#bc2a8d';
      mailruShareButtonImg.src = 'https://platform-cdn.sharethis.com/img/instagram.svg';

      copyPageUrlButton.style.backgroundColor = '#007bff';
      copyPageUrlButtonImg.src = chainLinkIcon;
      copyPageUrlButtonText.textContent = 'Copy';

      // add handler
      const site = 'https://cvh-quilt.venuiti.com/' + window.location.pathname;
      const urlToInstagram = `https://www.instagram.com?url=${site}`;
      // const urlToInstagram = 'https://www.instagram.com/accounts/login/?hl=en'
      mailruShareButton.addEventListener('click', () => openLinkInNewWindow(urlToInstagram));
      copyPageUrlButton.addEventListener('click', () => {
        copyToClipboard();
        copyPageUrlButtonText.textContent = 'Copied!';
      });
      newEmailButton.addEventListener('click', sendMail);

      // replace old to new icon
      stickyShareButtonRef?.replaceChild(mailruShareButton, mailruShareButtonOrig);
      stickyShareButtonRef?.replaceChild(copyPageUrlButton, vkShareButton);
      stickyShareButtonRef?.replaceChild(newEmailButton, emailButtonOrig);
    }, 300);
  }, [stickyShareButtonsContainerRef.current, sendMail]);

  return (
    <div ref={stickyShareButtonsContainerRef} className="hiddenInPrint">
      <StickyShareButtons
        config={{
          alignment: 'right',
          color: 'social',
          enabled: true,
          font_size: 16,
          labels: 'cta',
          language: 'en',
          min_count: 0,
          networks: ['vk', 'facebook', 'mailru', 'linkedin', 'twitter', 'email'],
          padding: 12,
          radius: 4,
          show_total: false,
          show_mobile: true,
          show_toggle: true,
          size: 48,
          top: 320,
        }}
      />
    </div>
  );
};

export { CustomShareButtons };
