import HttpClient from '../http-client/HttpClient';
import { login, register } from '../http-client/queries/accounts';
import { fetchProfile } from '../http-client/queries/profiles';
import { TLoginRequest, TRegisterRequest } from '../http-client/types';
import { actions, authStore } from './storage';

export class AuthService {
  constructor() {
    const auth = authStore.getState();
    if ((auth.user || {}).jwt) {
      HttpClient.authorize(auth.user.jwt);
    }
  }

  async login(data: TLoginRequest) {
    const response = await login(data);

    if (response.success) {
      HttpClient.authorize(response.returned.jwt);

      const profileRes = await fetchProfile();
      const user = {
        ...(response.returned || {}),
        ...(profileRes.returned || {}),
      };

      authStore.dispatch(actions.setUser(user));
    }

    return response;
  }

  logout() {
    HttpClient.unauthorize();
    authStore.dispatch(actions.removeUser());
  }

  async register(data: TRegisterRequest) {
    const response = await register(data);
    if (response.returned.jwt) {
      HttpClient.authorize(response.returned.jwt);
      const profileRes = await fetchProfile();
      const user = {
        ...(response.returned || {}),
        ...(profileRes.returned || {}),
      };
      authStore.dispatch(actions.setUser(user));
    }
    return response;
  }
}
