import { TImage } from "api/http-client/types";

export const fromUrlToBlob = async (images?: TImage[]) => {
  if (!images) return [];

  const files = [];

  for (const image of images) {
    const res = await fetch(image.full);
    const blobImg = await res.blob();

    if (image.name) {
      files.push(new File([blobImg], image.name.split('-').pop() as string, { type: blobImg.type }));
    }
  }

  return files;
}
