import React from 'react';
import { useTranslates } from 'providers/translates';
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
import { useAuth } from 'providers/auth-provider';
import { TProfile } from 'api/http-client/profileTypes';
import { useAppSelector } from 'hooks';

const Footer: React.FC = () => {
  const { translates } = useTranslates();
  const user = useAuth().user as unknown as TProfile;
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);

  const nameRow = `Name associated with your account: ${user?.firstName ?? ''} ${user?.lastName ?? ''}\n`;
  const emailRow = `Email address associated with your account: ${user?.email ?? ''}\n`;
  const quiltNameRow = `Name of the quilt you need help with: ${editableQuilt?.firstName ?? ''} ${editableQuilt?.lastName ?? ''}\n`;
  const issueOrConcernRow = `Issue or concern:`;

  const contactUsEmail = {
    subject: encodeURIComponent('I have a question about the Canadian Healing Quilt'),
    body: encodeURIComponent(
      `\n\n\nNote: If you are a current Canadian Healing Quilt user and are looking for assistance please provide us with the following information so we can help you as easily as possible:\n\n${nameRow}${emailRow}${quiltNameRow}${issueOrConcernRow}`
    ),
  };

  return (
    <footer className={styles.footer}>
      <div className="center">
        <div className={styles.footer_block}>
          <a
            href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Home.aspx"
            target="__blank"
            className="logo"
          >
            <img src="/img/general/logo-white-footer.png" alt="VirtualHospice" />
          </a>
          <div className={styles.footer_info}>
            <ul className={`${styles.footer_list} ${styles.isDesktop}`}>
              <li>
                <a
                  href={`mailto:info@virtualhospice.ca?subject=${contactUsEmail.subject}&body=${contactUsEmail.body}`}
                >
                  {translates?.footer?.contactUs || 'Contact us'}
                </a>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <Link to={'/howtouse/?visible=notice'} target="__blank" rel="noreferrer">
                  {translates?.footer?.privacyNotice || 'Privacy Notice'}
                </Link>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <a
                  href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Privacy+Policy.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translates?.footer?.privacyPolicy || 'Privacy Policy'}
                </a>
              </li>
            </ul>
            <ul className={`${styles.footer_list} ${styles.isMobile}`}>
              <li>
                <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
                  {translates?.footer?.contactUs || 'Contact us'}
                </a>
              </li>
            </ul>
            <ul className={`${styles.footer_list} ${styles.isMobile}`}>
              <li>
                <Link to={'/howtouse/?visible=notice'} target="__blank" rel="noreferrer">
                  {translates?.footer?.privacyNotice || 'Privacy Notice'}
                </Link>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <a
                  href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Privacy+Policy.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translates?.footer?.privacyPolicy || 'Privacy Policy'}
                </a>
              </li>
            </ul>

            <span className={styles.footer_copyright}>
              {translates?.footer?.copyright ||
                'Copyright 2016-2024 Canadian Virtual Hospice. All Rights Reserved.'}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
