import { postPhotoToQuiltAlbum, uploadQuiltImg } from 'api/http-client/queries/quilts';
import { TDefaultListValue } from '../../../api/http-client/types';
import { v4 as uuidv4 } from 'uuid';
import { createFileFromUrl } from 'utilities';
import { TQuilt } from './model';
import { getImagesOfQuilt } from 'api/http-client/queries/quiltQueries';
import { fromUrlToBlob } from '../helpers/from-url-to-blob';
import { SetStateAction } from 'react';

export const updateStateByValueInputList = (
  state: TDefaultListValue[],
  newValue: string,
  id: string
) => {
  const newState = [...state];

  const currentIndex = newState.findIndex((item) => item.id === id);

  newState[currentIndex] = {
    ...newState[currentIndex],
    value: newValue,
  };

  return newState;
};

export const createStateInputList = (state: TDefaultListValue[]) => {
  return [
    ...state,
    {
      id: uuidv4(),
      value: '',
    },
  ];
};

export const removeStateInputListById = (state: TDefaultListValue[], index: number) => {
  const newState = [...state];

  newState.splice(index, 1);

  return newState;
};

export const saveFirstStepData = async (
  editableQuiltStore: TQuilt,
  fileImageUrl: string | undefined,
  selectedImage: string | null,
  filesImages: File[],
  createId: () => Promise<string | void>
): Promise<void> => {
  sessionStorage.setItem('firstStepData', JSON.stringify(editableQuiltStore));
  
  if (fileImageUrl === undefined) return;
  
  const id = await createId();
  if (!id) return;

  sessionStorage.setItem('quiltId', id);

  const image = await createFileFromUrl(fileImageUrl, selectedImage ?? 'image');
  await uploadQuiltImg(id, image);

  if (filesImages.length > 0) {
    Promise.all(filesImages.map(async (file) => postPhotoToQuiltAlbum(id, file)));
  }
}

export const resetFirstStepData = async (
  setFileImageUrl: (value: SetStateAction<string | undefined>) => void,
  setSelectedImage: (value: SetStateAction<string | null>) => void,
  setFilesImages: (value: SetStateAction<File[]>) => void
) => {
  const quiltId = sessionStorage.getItem('quiltId');
  if (quiltId === null) return;

  const result = await getImagesOfQuilt(quiltId);

  if (!result) return;

  const { album, finalImage } = result;
  finalImage?.full && setFileImageUrl(finalImage.full);
  finalImage?.originalFileName && setSelectedImage(finalImage?.originalFileName)
  setFilesImages(await fromUrlToBlob(album));
}
