import { FC } from 'react';
import styles from './styles.module.scss';
import {
  BlockModel,
  BlockModelBig,
  ImageTypeModel,
  TDeletingBlock,
  TPositionBlock,
  TSideBlock,
} from './model';
import { BlockImageOrTextFull } from './BlockImageOrTextFull';
import { BlockImageOrTextHalf } from './BlockImageOrTextHalf';
import { Collapse } from '@mui/material';
import { CreateImagesBlock } from '../components/CreateImagesBlock';

interface BlocksProps {
  blocks: BlockModel[];
  currentBlockImage: BlockModelBig | null;
  setEditorDataById: (id: string, newValue: string, side?: TSideBlock) => void;
  addNewBlockText: (position: TPositionBlock, side?: TSideBlock, blockId?: string) => void;
  addNewBlockImage: (
    position: TPositionBlock,
    side?: TSideBlock,
    blockId?: string,
    isEdit?: boolean
  ) => void;
  changePositionBlocks: (id: string, side: TSideBlock, idTo: string, sideTo: TSideBlock) => void;
  setConfirmDeleting: React.Dispatch<
    React.SetStateAction<{
      openModal: boolean;
      side?: TSideBlock | undefined;
      block: BlockModel;
      typeDeleting: TDeletingBlock;
    }>
  >;
  updateBlockImage: (block: BlockModel) => void;
  setImageBlock: (image: ImageTypeModel | null) => void;
  imageName: string | null;
}

export const Blocks: FC<BlocksProps> = ({
  blocks,
  currentBlockImage,
  setImageBlock,
  updateBlockImage,
  setConfirmDeleting,
  setEditorDataById,
  addNewBlockText,
  addNewBlockImage,
  changePositionBlocks,
  imageName,
}) => {
  const isRenderCreateImageBlock = (block: BlockModel) => {
    return (
      !!currentBlockImage?.id &&
      block.id === currentBlockImage?.id &&
      currentBlockImage.position === block.position
    );
  };

  return (
    <div className={styles.blocks}>
      {blocks.map((block, index) => {
        return (
          <div id={`${block.id}`} key={index}>
            <Collapse in={!isRenderCreateImageBlock(block)} timeout={1000}>
              <div>
                {block.position === 'full' ? (
                  <BlockImageOrTextFull
                    key={block.id}
                    block={block}
                    updateBlockImage={updateBlockImage}
                    setConfirmDeleting={setConfirmDeleting}
                    setEditorDataById={setEditorDataById}
                    changePositionBlocks={changePositionBlocks}
                  />
                ) : block.position === 'header' ? null : (
                  <BlockImageOrTextHalf
                    key={block.id}
                    block={block}
                    setConfirmDeleting={setConfirmDeleting}
                    setEditorDataById={setEditorDataById}
                    addNewBlockText={addNewBlockText}
                    addNewBlockImage={addNewBlockImage}
                    changePositionBlocks={changePositionBlocks}
                  />
                )}
              </div>
            </Collapse>
            <Collapse in={isRenderCreateImageBlock(block)} timeout={1000}>
              <CreateImagesBlock imageName={imageName} setImageBlock={setImageBlock} />
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};
