import { publicPrivate, publishDraft } from '../api/http-client/queries/quilts';
import {
  privateMemorial,
  publicMemorial,
  publishMemorial,
} from '../api/http-client/queries/memorials';
import { notifyMessagesQuiltMemorialUpdateCreate } from '../utilities';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { initialStateBlocksData } from '../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepThree/const';
import { BlockModel } from '../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepThree/model';
import { setOpen } from 'store/reducers/notifySlice';
import { setEditableQuiltState } from 'store/reducers/quiltSlice';

interface UseQuiltParamsModel {
  id?: string;
}

interface UseQuiltReturnModel {
  publish: {
    onPublish: (isPublic: boolean, idIn?: string) => void;
    successPublish: boolean;
    setSuccessPublish: Dispatch<SetStateAction<boolean>>;
  };
  blocks: {
    getBlocksEditableQuilt: () => BlockModel[];
  };
  loading: boolean;
}

export const useQuilt = ({ id }: UseQuiltParamsModel): UseQuiltReturnModel => {
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [successPublish, setSuccessPublish] = useState(false);

  const onPublish = async (isPublic: boolean, idIn: string | undefined) => {
    if (!id ?? !idIn) return;

    const currentId = id || idIn;

    setLoading(true);

    try {
      await publishDraft(currentId, false);
      await publishMemorial(currentId);
      await publicPrivate(currentId, !isPublic);
      isPublic ? await publicMemorial(currentId) : await privateMemorial(currentId);

      dispatch(setEditableQuiltState({ ...editableQuilt, isPublished: true, isPublic }));
      setSuccessPublish(true);
    } catch (e) {
      dispatch(setOpen(e?.errors || notifyMessagesQuiltMemorialUpdateCreate.quiltCreatedError));
    } finally {
      setLoading(false);
    }
  };

  const getBlocksEditableQuilt = () => {
    try {
      return editableQuilt?.memorial?.data
        ? JSON.parse(editableQuilt?.memorial?.data)
        : [initialStateBlocksData];
    } catch (e) {
      console.error(e, 'problem parse memorial blocks!');
      return [initialStateBlocksData];
    }
  };

  return {
    publish: {
      onPublish,
      successPublish,
      setSuccessPublish,
    },
    blocks: {
      getBlocksEditableQuilt,
    },
    loading,
  };
};
