import { FC } from 'react';
import styles from '../styles.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export interface ArrowPropsModel {
  onClick?: () => void;
  disabled?: boolean;
}

const CustomRightArrow: FC<ArrowPropsModel> = ({ onClick, disabled }) => {
  return (
    <button onClick={onClick} className={styles.slide__button_next} disabled={disabled}>
      <ArrowBackIosIcon
        sx={{
          color: 'white',
          fontSize: '75px',
          transform: 'rotate(180deg)',
        }}
      />
    </button>
  );
};

const CustomLeftArrow: FC<ArrowPropsModel> = ({ onClick, disabled }) => {
  return (
    <button onClick={onClick} className={styles.slide__button_prev} disabled={disabled}>
      <ArrowBackIosIcon
        sx={{
          color: 'white',
          fontSize: '75px',
        }}
      />
    </button>
  );
};

export { CustomRightArrow, CustomLeftArrow };
