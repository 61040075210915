import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UsersState {
  isNeedUpdate: boolean;
}

const initialState: UsersState = {
  isNeedUpdate: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsNeedUpdateUsers: (state, action: PayloadAction<boolean>) => {
      state.isNeedUpdate = action.payload;
    },
  },
});

export const { setIsNeedUpdateUsers } = userSlice.actions;

export default userSlice;
