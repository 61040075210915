import React, { Dispatch, SetStateAction } from 'react';
import { Dialog, Stack } from '@mui/material';
import styles from './styles.module.scss';

export const DefaultDialog: React.FC<{
  open: boolean;
  setDialog: Dispatch<SetStateAction<boolean>>;
  title: string;
  text: string;
  confirmAction: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  noCancelBtn?: boolean;
}> = ({
  open,
  setDialog,
  title,
  text,
  confirmAction,
  confirmBtnText = 'Yes',
  cancelBtnText = 'No',
  noCancelBtn,
}) => {
  const handleClose = () => setDialog(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: '700px',
        },
      }}
    >
      <Stack justifyContent="center" alignItems="center" p={3} spacing={2}>
        <div className={`inputWithTitle ${styles.centeredText}`}>
          <span className={styles.noCapitalize}>{title}</span>
        </div>

        <div className={`${styles.text}`} dangerouslySetInnerHTML={{ __html: text || '' }}></div>

        <Stack direction="row" spacing={2} pt={1}>
          {!noCancelBtn && (
            <button className={`customButton ${styles.button}`} onClick={handleClose}>
              {cancelBtnText}
            </button>
          )}
          <button className={`customButton ${styles.button}`} onClick={confirmAction}>
            {confirmBtnText}
          </button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
