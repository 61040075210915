import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TQuilt } from '../../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepOne/model';
import { BlockModel } from '../../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepThree/model';

interface QuiltStateModel {
  editableQuilt: TQuilt;
  currentBlocks: BlockModel[];
  isTriggerResetValuesComparing: boolean;
}

const initialState: QuiltStateModel = {
  editableQuilt: {},
  currentBlocks: [],
  isTriggerResetValuesComparing: false,
};

const quiltSlice = createSlice({
  name: 'quilt',
  initialState,
  reducers: {
    setEditableQuiltState: (state, action: PayloadAction<TQuilt>) => {
      state.editableQuilt = action.payload;
    },

    resetEditableQuiltState: (state) => {
      state.editableQuilt = {};
    },

    setCurrentBlocks: (state, action: PayloadAction<BlockModel[]>) => {
      state.currentBlocks = action.payload;
    },

    resetCurrentBlocks: (state) => {
      state.currentBlocks = [];
    },

    setIsTriggerResetValuesComparing: (state, action: PayloadAction<boolean>) => {
      state.isTriggerResetValuesComparing = action.payload;
    },
  },
});

export const {
  setEditableQuiltState,
  resetEditableQuiltState,
  setCurrentBlocks,
  resetCurrentBlocks,
  setIsTriggerResetValuesComparing,
} = quiltSlice.actions;

export default quiltSlice;
