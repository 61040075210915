import { FC, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { TDirection } from 'components/HomeCarousel/model';
import { useCarouselHome } from 'hooks/useCarouselHome';
import MainCarouseRow from './components/Row/MainCarouseRow';
import { Loader } from 'components/Loader/Loader';
import { CustomLeftArrow, CustomRightArrow } from './components/Arrows';
import { QuiltsResponseMega } from '../../api/http-client/types';
import { QuiltsGroup } from 'common/types/quilts-group/quilts-group';

const HomeCarousel: FC = () => {
  const [moveSide, setMoveSide] = useState<TDirection>('left');
  const [positionContainer, setPositionContainer] = useState(0);

  const containerMoveRef = useRef<HTMLDivElement | null>(null);
  const containerMainRef = useRef<HTMLDivElement | null>(null);
  const sliderContentRef = useRef<HTMLDivElement | null>(null);

  const { data, loading, settingData, setData, option } = useCarouselHome({
    containerMoveRef,
    containerMainRef,
    moveSide,
    setMoveSide,
  });

  const moveSlider = (direction: TDirection) => {
    if (!containerMainRef.current) return;

    const stepWidth = containerMainRef.current.clientWidth;
    const fullOffset = stepWidth * getGroupsRows.length - stepWidth;

    if (direction === 'left' && +positionContainer === 0) {
      return;
    } else if (direction === 'right' && fullOffset === +positionContainer) {
      setData((state) => {
        if (!state) return;

        return [...state, ...state];
      });

      setTimeout(() => {
        setPositionContainer((state) => {
          return state + stepWidth;
        });
      }, 100);
      return;
    }
    // default behavior
    else {
      setPositionContainer((state) => {
        return direction === 'right' ? state + stepWidth : state - stepWidth;
      });
    }
  };

  const getWidthWrap = option;

  const getGroupsRows = useMemo(() => {
    if (!data) return [];

    if (data.length <= settingData.rowLimit) {
      return [data];
    }

    let group: (QuiltsResponseMega | null)[][] = [];
    const total =
      data?.reduce(
        (total, item) => {
          group.push(item);

          if (group.length === settingData.rowLimit) {
            total.push(group);
            group = [];
          }

          return total;
        },
        [] as QuiltsGroup[][][]
      ) || [];

    if (group.length !== 0) {
      total.push(group);
    }

    return total;
  }, [data]);

  return (
    <div className={styles.container__wrap}>
      {getGroupsRows.length > 1 && positionContainer !== 0 && (
        <CustomLeftArrow onClick={() => moveSlider('left')} />
      )}

      <div
        className={styles.container}
        style={{
          transform: `translateX(-${positionContainer}px)`,
        }}
      >
        {getGroupsRows.map((group, index) => {
          return (
            <div ref={containerMainRef} className={styles.slider__container} key={index}>
              <div
                className={styles.slider__wrap}
                style={{
                  width: `${getWidthWrap}`,
                }}
              >
                <div
                  ref={sliderContentRef}
                  className={styles.slider__content}
                  style={{
                    width: `${getWidthWrap}`,
                  }}
                >
                  {group?.map((items, index) => {
                    return (
                      <MainCarouseRow
                        option={getWidthWrap}
                        key={index}
                        items={items}
                        indexOfRenderRow={index}
                        settingData={settingData}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <Loader loading={loading} />
      </div>

      {getGroupsRows.length > 1 && <CustomRightArrow onClick={() => moveSlider('right')} />}
    </div>
  );
};

export default HomeCarousel;
