import { BlockModel, TDeletingBlock, TPositionBlock, TSideBlock } from './model';
import RichTextEditor from '../../RichTextEditor';
import styles from './styles.module.scss';
import React, { FC } from 'react';
import { styleImageByType } from './const';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

interface BlockImageOrTextHalfProps {
  block: BlockModel;
  setEditorDataById: (id: string, newValue: string, side?: TSideBlock) => void;
  addNewBlockText: (position: TPositionBlock, side?: TSideBlock, blockId?: string) => void;
  addNewBlockImage: (
    position: TPositionBlock,
    side?: TSideBlock,
    blockId?: string,
    isEdit?: boolean
  ) => void;
  changePositionBlocks: (id: string, side: TSideBlock, idTo: string, sideTo: TSideBlock) => void;
  setConfirmDeleting: React.Dispatch<
    React.SetStateAction<{
      openModal: boolean;
      side?: TSideBlock | undefined;
      block: BlockModel;
      typeDeleting: TDeletingBlock;
    }>
  >;
}

export const BlockImageOrTextHalf: FC<BlockImageOrTextHalfProps> = ({
  block,
  setConfirmDeleting,
  setEditorDataById,
  addNewBlockText,
  addNewBlockImage,
  changePositionBlocks,
}) => {
  const startMoveBlock = (event: any, id: string, side: TSideBlock) => {
    if (!event?.dataTransfer) return;

    const transferData = JSON.stringify({
      id,
      side,
    });

    event.dataTransfer.setData('application/json', transferData);
  };

  const blockDragOver = (event: any) => event.preventDefault();

  const blockDrop = (event: any, idTo: string) => {
    event.preventDefault();

    const transferData = JSON.parse(event.dataTransfer.getData('application/json'));
    const toSide = event.currentTarget.dataset.side;
    const fromSide = transferData.side;

    changePositionBlocks(transferData?.id || '', fromSide, idTo, toSide);
  };

  return (
    <div className={styles.editorBlock2__top} key={block.id}>
      <div
        className={styles.editorBlock2__top_wrapper}
        data-side="left"
        onDragStart={(e) => startMoveBlock(e, block.id, 'left')}
        onDrop={(e) => blockDrop(e, block.id)}
        onDragOver={blockDragOver}
        draggable={true}
      >
        {typeof block.editorData === 'object' && block.editorData.left?.isRender ? (
          <div className="editorBlock">
            <RichTextEditor
              editorData={block.editorData.left?.value}
              setEditorData={(value) => setEditorDataById(block.id, value, 'left')}
              quiltId={''}
              height={400}
            ></RichTextEditor>

            <div className={styles.actionButtons}>
              <div
                className={styles.details__addPhotoActionSvg}
                onClick={() => {
                  setConfirmDeleting({
                    openModal: true,
                    side: 'left',
                    typeDeleting: 'editor',
                    block,
                  });
                }}
              >
                <DeleteOutlineIcon />
              </div>
            </div>
          </div>
        ) : block.hideBlock === 'left' ? null : block.image &&
          'left' in block.image &&
          block.image.left?.image?.file &&
          block.image.left?.isRender ? (
          <div className={styles.editorBlock__image}>
            <img
              src={block.image.left.image.file}
              alt="memory"
              className={styleImageByType[block.image.left.image.type]}
            />
            <div className={styles.actionButtons}>
              <div
                className={styles.details__addPhotoActionSvg}
                onClick={() => {
                  addNewBlockImage(block.position, 'left', block.id, true);
                }}
              >
                <EditIcon />
              </div>
              <div
                className={styles.details__addPhotoActionSvg}
                onClick={() => {
                  setConfirmDeleting({
                    openModal: true,
                    side: 'left',
                    typeDeleting: 'image',
                    block,
                  });
                }}
              >
                <DeleteOutlineIcon />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.editorBlock__add}>
            <div
              className={styles.editorBlock__addIcon}
              onClick={() => addNewBlockText('half', 'left', block.id)}
            >
              <img src="/img/general/text-size-icon.png" alt="text-size" />
              <span>Click here to add text</span>
            </div>

            <span className={styles.editorBlock__or}>OR</span>

            <div
              className={styles.editorBlock__addIcon}
              onClick={() => addNewBlockImage('half', 'left', block.id)}
            >
              <img src="/img/general/image-icon.png" alt="iamge-icon" />
              <span>Click here to add images</span>
            </div>
          </div>
        )}
      </div>

      <div
        className={styles.editorBlock2__top_wrapper}
        data-side="right"
        onDragStart={(e) => startMoveBlock(e, block.id, 'right')}
        onDrop={(e) => blockDrop(e, block.id)}
        onDragOver={blockDragOver}
        draggable={true}
      >
        {typeof block.editorData === 'object' && block.editorData.right?.isRender ? (
          <div className="editorBlock">
            <RichTextEditor
              editorData={block.editorData.right?.value}
              setEditorData={(value) => setEditorDataById(block.id, value, 'right')}
              quiltId={''}
              height={400}
            ></RichTextEditor>
            <div className={styles.actionButtons}>
              <div
                className={styles.details__addPhotoActionSvg}
                onClick={() => {
                  setConfirmDeleting({
                    openModal: true,
                    side: 'right',
                    typeDeleting: 'editor',
                    block,
                  });
                }}
              >
                <DeleteOutlineIcon />
              </div>
            </div>
          </div>
        ) : block.image &&
          'right' in block.image &&
          block.image.right?.image?.file &&
          block.image.right?.isRender ? (
          <div className={styles.editorBlock__imageRight}>
            <div className={styles.imgWrapper}>
              <img
                src={block.image.right.image.file || ''}
                alt="memory"
                className={styleImageByType[block.image.right.image.type]}
              />
              <div className={styles.actionButtons}>
                <div
                  className={styles.details__addPhotoActionSvg}
                  onClick={() => {
                    addNewBlockImage(block.position, 'right', block.id, true);
                  }}
                >
                  <EditIcon />
                </div>
                <div
                  className={styles.details__addPhotoActionSvg}
                  onClick={() => {
                    setConfirmDeleting({
                      openModal: true,
                      side: 'right',
                      typeDeleting: 'image',
                      block,
                    });
                  }}
                >
                  <DeleteOutlineIcon />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.editorBlock__add}>
            <div
              className={styles.editorBlock__addIcon}
              onClick={() => addNewBlockText('half', 'right', block.id)}
            >
              <img src="/img/general/text-size-icon.png" alt="text-size" />
              <span>Click here to add text</span>
            </div>

            <span className={styles.editorBlock__or}>OR</span>

            <div
              className={styles.editorBlock__addIcon}
              onClick={() => addNewBlockImage('half', 'right', block.id)}
            >
              <img src="/img/general/image-icon.png" alt="iamge-icon" />
              <span>Click here to add images</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
