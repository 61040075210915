import { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPublicQuiltWithMemorial } from 'api/http-client/queries/quiltQueries';
import { httpMessages } from 'utilities/constants';
import { useTranslates } from 'providers/translates';
import { setEditableQuiltState } from 'store/reducers/quiltSlice';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DialogBlocks } from 'components/CreateMemorialQuiltComponents/DialogBlocks';
import { BlockModel } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepThree/model';
import { Loader } from 'components/Loader/Loader';
import { AccessPermissionsTextEnum } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { CustomShareButtons } from 'components/CustomShareButtons/CustomShareButtons';

const QuiltView: FC = () => {
  const { id } = useParams();
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);

  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [blocks, setBlocks] = useState<BlockModel[]>([]);
  const [editButtonStatus, setEditButtonStatus] = useState<AccessPermissionsTextEnum>(
    AccessPermissionsTextEnum.NO_EDIT
  );

  useEffect(() => {
    if (!id) return;
    setLoading(true);

    fetchPublicQuiltWithMemorial(id)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          response.data.returned?.isOwner
            ? setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL)
            : response.data.returned?.isContributor
              ? response.data.returned?.accessMode ===
                AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL
                ? setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL)
                : setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES)
              : setEditButtonStatus(AccessPermissionsTextEnum.NO_EDIT);
          dispatch(setEditableQuiltState(response.data.returned));
          if (response.data.returned?.memorial?.data) {
            const blockData = JSON.parse(response.data.returned?.memorial?.data);
            if (Array.isArray(blockData)) {
              setBlocks(blockData);
            }
          }
        } else {
          dispatch(setOpen(`No quilt found`));
        }
      })
      .catch(() =>
        setFetchError(
          translates?.httpMessages?.fetchPublicQuiltWithMemorialError ||
            httpMessages.fetchPublicQuiltWithMemorialError
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, translates?.httpMessages?.fetchPublicQuiltWithMemorialError]);

  useEffect(() => {
    return () => {
      dispatch(setEditableQuiltState({}));
    };
  }, [dispatch]);

  if (fetchError) return <div style={{ textAlign: 'center', marginTop: '50px' }}>{fetchError}</div>;

  return (
    <>
      <DialogBlocks
        isTributes={editableQuilt?.isEnableTributesAndCondolence}
        isViewPage={true}
        blocksProps={blocks}
        editButtonStatus={editButtonStatus}
      />
      <CustomShareButtons />
      <Loader loading={loading} />
    </>
  );
};

export default QuiltView;
