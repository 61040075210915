import { editUnpublishedQuilt, getQuiltsMega } from 'api/http-client/queries/quiltQueries';
import { QuiltsResponseMega } from 'api/http-client/types';
import { TDirection } from 'components/HomeCarousel/model';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { settingsRowL, settingsRowM, settingsRowS } from '../helpers/carousel/get-settings-row';
import { OptionsWrapEnum, OptionsRowType } from '../common/enums/carousel/carousel.enum';
import { createSubArraysFromQuilts } from '../helpers/carousel/create-sub-arrays-from-quilts';

interface UseCarouselHomeReturnModel {
  option: OptionsWrapEnum;
  toggleZIndex?: (control: boolean) => void;
  getMovePositionBySide?: () => string;
  handleTransitionEnd?: () => void;
  data: (QuiltsResponseMega | null)[][] | undefined;
  setData: Dispatch<SetStateAction<(QuiltsResponseMega | null)[][] | undefined>>;
  settingData: DataSettingModel;
  loading: boolean;
}

interface UseCarouselHomeParamsModel {
  containerMoveRef: MutableRefObject<HTMLDivElement | null>;
  containerMainRef: MutableRefObject<HTMLDivElement | null>;
  moveSide: TDirection;
  setMoveSide: Dispatch<SetStateAction<TDirection>>;
}

export interface DataSettingModel {
  limitMega: number;
  groupLimit: number;
  rowLimit: number;
}

export const useCarouselHome = ({
  containerMoveRef,
  containerMainRef,
  moveSide,
  setMoveSide,
}: UseCarouselHomeParamsModel): UseCarouselHomeReturnModel => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<(QuiltsResponseMega | null)[][]>();
  const [loading, setLoading] = useState<boolean>(false);

  const matches2000 = useMediaQuery('(max-width: 2000px)');
  const matches1450 = useMediaQuery('(max-width: 1450px)');
  const matches1240 = useMediaQuery('(max-width: 1240px)');

  const [settingData, setSettingData] = useState<DataSettingModel>({
    limitMega: 150,
    groupLimit: 7,
    rowLimit: matches1240 ? 6 : 5,
  });

  let settings: { settingsRow: OptionsRowType[][]; option: OptionsWrapEnum } = useMemo(() => {
    if (matches1450) {
      return {
        settingsRow: settingsRowS,
        option: OptionsWrapEnum.s,
      };
    } else if (matches2000) {
      return {
        settingsRow: settingsRowM,
        option: OptionsWrapEnum.m,
      };
    } else {
      return {
        settingsRow: settingsRowL,
        option: OptionsWrapEnum.l,
      };
    }
  }, [matches2000, matches1450]);

  useEffect(() => {
    // set group limit
    if (matches2000 && !matches1240) {
      setSettingData((data) => {
        return {
          ...data,
          groupLimit: 4,
        };
      });
    }

    setLoading(true);

    getQuiltsMega(settingData.limitMega)
      .then((data) => {
        if (!data) return;

        // visible functionality (view quilt on home page)
        const visibleId = searchParams.get('visible');

        if (visibleId) {
          editUnpublishedQuilt(visibleId).then((quilt) => {
            const dataWithoutThisQuilt = data.filter((item) => item.id !== +visibleId);

            setData(getPreparingData([quilt, ...dataWithoutThisQuilt]));
          });
        } else {
          setData(getPreparingData(data));
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const getPreparingData = (data: QuiltsResponseMega[]) => {
    if (data.length < settingData.groupLimit) {
      return [data];
    }

    const total = createSubArraysFromQuilts(settings, data);

    return total;
  };

  return {
    option: settings.option,
    data,
    loading,
    setData,
    settingData,
  };
};
