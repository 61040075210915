import { FC, ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Tooltip,
  styled,
  TooltipProps,
  Box,
  Button,
  Typography,
  tooltipClasses,
  Zoom,
  Fade,
  Stack,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { breakpoints } from '../utilities/responsiveBreakpoints';
import { useTranslates } from '../providers/translates';
import { useRedirectAndScroll } from '../hooks';

interface WithTooltipProps {
  child: ReactElement<any, any>;
  url: string;
  name: string;
  id: number;
  dateOfBirth: number;
  setError: (e: boolean) => void;
  customWidth: number;
  customHeight: number;
  dateOfPassing?: number;
}

const style = {
  position: 'absolute' as 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 420,
  height: 480,
  background: '#24466a',
  boxShadow: 24,
  p: 1,
  outline: 'none',
};

const styleMobile = {
  position: 'absolute' as 'absolute',
  transform: 'translate(-49%, 100%)',
  boxShadow: 24,
  p: 1,
  outline: 'none',
};

const WithTooltip: FC<WithTooltipProps> = ({
  child,
  url,
  name,
  id,
  dateOfBirth,
  dateOfPassing,
  customWidth,
  customHeight,
  setError,
}) => {
  const { onlyRedirect } = useRedirectAndScroll({});
  const { translates } = useTranslates();

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      backgroundColor: `${
        document.documentElement.clientWidth <= breakpoints.mobile.max && 'transparent'
      }`,
    },
  });

  const onView = (id: number) => {
    // fetchPublicQuiltWithMemorial(id)
    //   .then((res) => {
    //     if (res.data.success) {
    //       setError(false);
    //     }
    //     if (res.data.returned.memorial) {
    //       navigate(`quilts/${id}/memorial`);
    //     } else {
    //       navigate(`memory/${id}`);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setError(true);
    //   });
    onlyRedirect(`quilts/${id}/memorial`);
  };

  return (
    <CustomWidthTooltip
      onClick={() => {
        if (isMobile) {
          onView(id);
        }
      }}
      TransitionComponent={
        document.documentElement.clientWidth > breakpoints.mobile.max ? Zoom : Fade
      }
      // open={true}
      placement={document.documentElement.clientWidth <= breakpoints.mobile.max ? 'top' : 'bottom'}
      title={
        document.documentElement.clientWidth >= breakpoints.mobile.max ? (
          <Box
            sx={
              document.documentElement.clientWidth >= breakpoints.tablet.max
                ? style
                : { width: '100%' }
            }
          >
            <img
              src={url}
              alt={name}
              style={{
                width: '100%',
                height: '80%',
                padding: '3px',
                objectFit: 'cover',
              }}
            />

            <Stack direction="row" spacing={1}>
              <Stack justifyContent="start" alignItems="start" mt={1} ml={1}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ textAlign: 'center', fontWeight: 700 }}
                >
                  {name}
                </Typography>
                <Box sx={{ textAlign: 'center', position: 'relative' }}>
                  <Typography component="span" id="modal-modal-description">
                    {dateOfBirth} - {dateOfPassing || 'present'}
                  </Typography>
                </Box>
              </Stack>

              <Box
                style={{
                  textDecoration: 'none',
                  position: `${
                    document.documentElement.clientWidth <= 1024 ? 'static' : 'absolute'
                  }`,
                  right: '15px',
                  bottom: '15px',
                  background: '#57a0c1',
                  color: 'white',
                  borderRadius: '6px',
                  padding: '0',
                }}
              >
                <Button
                  sx={{
                    width: `${document.documentElement.clientWidth <= 1024 ? '100%' : 'inherit'}`,
                    color: 'white',
                    padding: '2px 8px',
                    paddingRight: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    position: 'relative',
                  }}
                  onClick={() => onView(id)}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: 'white',
                      fontWeight: 700,
                      marginTop: '2px',
                    }}
                  >
                    {translates?.carouselCard?.carouselCardBtnLabel || 'View'}
                  </Typography>

                  <ArrowRightIcon sx={{ fontSize: '34px', position: 'absolute', right: '-6px' }} />
                </Button>
              </Box>
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              ...styleMobile,
              bottom: 0,
              width: `${customWidth}px`,
              height: `${customHeight}px`,
            }}
          >
            <Stack direction="row" justifyContent="start" alignItems="start">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: 'center', fontWeight: 700 }}
                fontSize={17}
              >
                {name}
              </Typography>

              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Typography component="span" id="modal-modal-description" fontSize={14}>
                  {dateOfBirth} - {dateOfPassing || 'present'}
                </Typography>
              </Box>
            </Stack>

            <Box
              style={{
                textDecoration: 'none',
                position: `${document.documentElement.clientWidth <= 1024 ? 'static' : 'absolute'}`,
                right: 0,
                bottom: 0,
              }}
            >
              <Button
                sx={{
                  width: `${document.documentElement.clientWidth <= 1024 ? '100%' : 'inherit'}`,
                  color: 'green',
                }}
                onClick={() => onView(id)}
              >
                <Typography component="span" fontSize={14}>
                  {translates?.carouselCard?.carouselCardBtnLabel || 'View'}
                </Typography>
                <ArrowRightIcon sx={{ fontSize: '12px', verticalAlign: 'middle', ml: '10px' }} />
              </Button>
            </Box>
          </Box>
        )
      }
    >
      {child}
    </CustomWidthTooltip>
  );
};

export default WithTooltip;
