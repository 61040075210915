import React from 'react';

import { Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { colors } from 'utilities';

interface LightTooltipProps extends TooltipProps {
  maxWidth?: string | number;
}

export const LightTooltip = styled(({ className, maxWidth = 300, ...props }: LightTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: colors.blue,
    maxWidth: maxWidth,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: '12px',
  },
}));

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.blue,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: '12px',
  },
}));
