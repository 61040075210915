import { OptionsRowType, OptionsWrapEnum } from '../../common/enums/carousel/carousel.enum';

interface UseSettingsRowPropsModel {
  rowId: number;
  option: OptionsWrapEnum;
}

interface UseSettingsRowModel {
  settings: OptionsRowType[];
}

export const settingsRowL = [
  [
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
  ],
];

export const settingsRowM = [
  [
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
  ],
];

export const settingsRowS = [
  [
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type,
  ],
  [
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
    OptionsRowType.Stub,
  ],
  [
    OptionsRowType.Type1,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type,
    OptionsRowType.Stub,
    OptionsRowType.Type1,
  ],
];

export const getSettingsRow = ({
  rowId,
  option,
}: UseSettingsRowPropsModel): UseSettingsRowModel => {
  const settings: OptionsRowType[] = [];

  if (option === OptionsWrapEnum.l) {
    settings.push(...(settingsRowL[rowId] ? settingsRowL[rowId] : []));
  }

  if (option === OptionsWrapEnum.m) {
    settings.push(...(settingsRowM[rowId] ? settingsRowM[rowId] : []));
  }

  if (option === OptionsWrapEnum.s) {
    settings.push(...(settingsRowS[rowId] ? settingsRowS[rowId] : []));
  }

  return {
    settings,
  };
};
