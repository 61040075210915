import { httpClient } from '..';
import { TMemorialData, TMemorialPost, TTributeRecord } from '../types';

export const fetchMemorial = async (id: string | number) => {
  const data = await httpClient.get(`/quilts/${id}/memorial`);
  return data;
};
//TODO
export const postMemorial = async (id: string, body: TMemorialData) => {
  const data = await httpClient.post(`/quilts/${id}/memorial`, {
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const updateMemorial = async (id: string, body: TMemorialPost) => {
  const data = await httpClient.post(`/quilts/${id}/memorial`, {
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const fetchPublicMemorial = async (id: string | number) => {
  const data = await httpClient.get(`quilts/${id}/memorial/shared/public`);
  return data;
};

export const publicMemorial = async (id: string) => {
  const { data } = await httpClient.put(`/quilts/${id}/memorial/public`);
  return data;
};

export const privateMemorial = async (id: string) => {
  const { data } = await httpClient.put(`/quilts/${id}/memorial/private`);
  return data;
};

export const publishMemorial = async (id: string) => {
  const { data } = await httpClient.put(`/quilts/${id}/memorial/publish`);
  return data;
};

export const unpublishMemorial = async (id: string) => {
  const { data } = await httpClient.put(`/quilts/${id}/memorial/unpublish`);
  return data;
};

export const enabledTributesAndCondolences = async (id: string, value: boolean) => {
  const { data } = await httpClient.put(`/quilts/${id}/memorial/enabled-tributes-and-condolences`, {
    data: { isEnableTributesAndCondolence: value },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const postTributesAndCondolences = async (id: string, body: TTributeRecord) => {
  const { data } = await httpClient.post(`/quilts/${id}/memorial/tributes-and-condolences`, {
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const deleteTributesAndCondolences = async (id: string, tributeId: string) => {
  const { data } = await httpClient.delete(
    `/quilts/${id}/memorial/tributes-and-condolences/${tributeId}`
  );
  return data;
};

export const deleteMemorial = async (id: string) => {
  const { data } = await httpClient.delete(`/quilts/${id}/memorial`);
  return data;
};

export const postMemorialEditorImg = async (id: string, image: File) => {
  const data = await httpClient.post(`/quilts/${id}/memorial/images`, {
    data: { image },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const postMemorialLightCandle = async (id: string, name: string) => {
  const { data } = await httpClient.post(`/quilts/${id}/memorial/light-a-candle`, {
    data: JSON.stringify({
      name,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const deleteMemorialLightCandle = async (id: string, candleId: string) => {
  const { data } = await httpClient.delete(`/quilts/${id}/memorial/light-a-candle/${candleId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};
