import { MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useTranslates } from '../../providers/translates';
import { TTributeRecord } from '../../api/http-client/types';

import styles from './styles.module.scss';
import { setOpen } from 'store/reducers/notifySlice';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  deleteMemorialLightCandle,
  deleteTributesAndCondolences,
  postMemorialLightCandle,
  postTributesAndCondolences,
} from '../../api/http-client/queries/memorials';
import { Loader } from '../Loader/Loader';
import { ShowCandles } from './showCandles';
import { ShowTributes } from './showTributes';
import { useAuth } from 'providers/auth-provider';
import { getUserQuilts } from 'api/http-client/queries/profileQueries';
import { CaptchaTooltip } from './captchaTooltip';
import { DefaultDialog } from '../DefaultDialog';
import { sortedIcons } from './icons-svg';

// const SHOWN_RECORDS_NUMB = 3;
const MAX_MESSAGE_LENGTH = 320;

interface Props {
  records?: TTributeRecord[];
  onAddRecord?: () => void;
  onShare?: (tributeData: TTributeRecord, callback: () => void) => void;
  onCancel?: () => void;
  onDelete?: (id: string, callback: () => void) => void;
  isModalOpened?: boolean;
  deletable?: boolean;
}

type ActionsNameType = 'shareCandle' | 'shareTributes';

interface ActionBodyModel {
  errors: string[];
  captcha: string | null;
}

interface DialogDeleteBodyModel {
  action: (id?: string) => void;
  isOpen: boolean;
  titleModal: (name?: string) => string;
}

interface DialogDeleteSettingModel extends DialogDeleteBodyModel {
  close: () => void;
}

type ActionType = Record<ActionsNameType, ActionBodyModel>;
type DialogDeleteType = Record<ActionsNameType, DialogDeleteBodyModel>;

const initialEditRecord = {
  firstName: '',
  lastName: '',
  message: '',
  email: '',
  icon: 'none',
};

const validate = (record: TTributeRecord): string[] => {
  const errors = [];

  if (!record.lastName) errors.push('Name');
  if (!record.message) errors.push('Message');
  if (!record.icon || record.icon === 'none') errors.push('Icon');

  return errors;
};

// component
// TODO Take out values above in file

const TributesAndCondolences = ({
  records = [],
  isModalOpened = false,
  onAddRecord = () => {},
  onShare = () => {},
  onCancel = () => {},
  onDelete = () => {},
  deletable = false,
}: Props) => {
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();

  const param = useParams();
  const { user } = useAuth();

  const editorData = useAppSelector((state) => state.quilt.editableQuilt);
  const mobile = useMediaQuery('(max-width:600px');

  const [lightCandleName, setLightCandleName] = useState<string>();
  const [editableRecord, setEditableRecord] = useState<TTributeRecord>(initialEditRecord);
  const [actionData, setActionData] = useState<ActionType>({
    shareCandle: {
      errors: ['Name'],
      captcha: null,
    },
    shareTributes: {
      errors: ['First Name', 'Message', 'Icon'],
      captcha: null,
    },
  });
  const [candles, setCandles] = useState(editorData?.memorial?.candles || []);
  const [editable, setEditable] = useState(false);
  const [tributesCandles, setTributesCandles] = useState(
    editorData?.memorial?.tributesAndCondolences || []
  );
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<DialogDeleteType>({
    shareCandle: {
      action: deleteCandle,
      isOpen: false,
      titleModal: () => 'Are you sure you want to delete this candle?',
    },
    shareTributes: {
      action: deleteTributesAndCondolence,
      isOpen: false,
      titleModal: () => 'Are you sure you want to delete this message?',
    },
  });

  useEffect(() => {
    if (editorData?.id && user)
      getUserQuilts().then(
        (resp) => resp.some((quilt) => quilt.id === editorData?.id) && setEditable(true)
      );
  }, [user, editorData?.id]);

  useEffect(() => {
    if (actionData.shareTributes.captcha) {
      shareTributes();
    }
  }, [actionData.shareTributes.captcha]);

  useEffect(() => {
    if (actionData.shareCandle.captcha) {
      shareCandle();
    }
  }, [actionData.shareCandle.captcha]);

  const shareTributes = async () => {
    setLoading(true);
    const body: TTributeRecord = {
      firstName: editableRecord.firstName,
      lastName: editableRecord.lastName,
      message: editableRecord.message,
      icon: editableRecord.icon,
    };

    const errors = validate(body);

    setActionData((state) => {
      return {
        ...state,
        shareTributes: {
          ...actionData.shareTributes,
          errors,
        },
      };
    });

    if (errors.length !== 0) {
      setLoading(false);
      // dispatch(setOpen(errors.map(error=>`${error}`).join(', ')+' should be filled'))

      if (errors.indexOf('Icon') !== -1) {
        dispatch(setOpen('Please choose an icon for your message.'));
      } else if (errors.indexOf('Name') !== -1 && errors.indexOf('Message') !== -1) {
        dispatch(setOpen('Please enter your name and a message.'));
      } else if (errors.indexOf('Name') !== -1) {
        dispatch(setOpen('Please enter your name.'));
      } else if (errors.indexOf('Message') !== -1) {
        dispatch(setOpen('Please enter your message.'));
      }

      return;
    }

    if (!!param?.id) {
      if (!!actionData.shareTributes.captcha) {
        await postTributesAndCondolences(param.id, body)
          .then((res) => {
            setTributesCandles((prev) => [...prev, { id: res.returned, ...body }]);
            setEditableRecord(initialEditRecord);
            dispatch(setOpen('Success!'));
          })
          .catch((e) => {
            dispatch(setOpen('Something went wrong, please try again later'));
          });
      }
    } else {
      dispatch(setOpen('Sorry this quilt was not published'));
    }
    setLoading(false);
  };

  const shareCandle = async () => {
    setLoading(true);

    setActionData((state) => {
      return {
        ...state,
        shareCandle: {
          ...actionData.shareCandle,
          errors: lightCandleName ? [] : ['Name'],
        },
      };
    });

    if (!lightCandleName) {
      dispatch(setOpen('Please enter your name.'));
      setLoading(false);
      return;
    }

    if (param?.id) {
      if (!!actionData.shareCandle.captcha) {
        await postMemorialLightCandle(param.id, lightCandleName)
          .then((res: { returned: number; success: boolean }) => {
            if (res.success) {
              dispatch(setOpen('Success!'));
              setCandles((prev) => [...prev, { id: res.returned, name: lightCandleName }]);
              setLightCandleName('');
            }
          })
          .catch(() => {
            dispatch(setOpen('Something went wrong, please try again later'));
          });
      }
    } else {
      dispatch(setOpen('Sorry this quilt was not published'));
    }
    setLoading(false);
  };

  // Logic for deleting candles and TributesAndCondolence
  const changeDeleteDialogBySetting = (
    type: ActionsNameType,
    isModal: boolean,
    idAction?: string,
    action?: (id: string) => void
  ) => {
    setDeleteDialog((state) => {
      return {
        ...state,
        [type]: {
          ...deleteDialog[type],
          isOpen: isModal,
          action: () => action && idAction && action(idAction),
        },
      };
    });
  };

  const getSettingDeleteModal = useMemo((): DialogDeleteSettingModel | null => {
    return deleteDialog.shareTributes.isOpen
      ? {
          ...deleteDialog.shareTributes,
          close: () => changeDeleteDialogBySetting('shareTributes', false),
        }
      : deleteDialog.shareCandle.isOpen
        ? {
            ...deleteDialog.shareCandle,
            close: () => changeDeleteDialogBySetting('shareCandle', false),
          }
        : null;
  }, [deleteDialog]);

  // Candle
  function deleteCandle(id?: string) {
    if (!id) return;

    changeDeleteDialogBySetting('shareCandle', true, id, actionDeleteCandle);
  }

  function actionDeleteCandle(id?: string) {
    if (!editorData?.id || !id) return;

    setLoading(true);

    deleteMemorialLightCandle(editorData?.id, id)
      .then(() => {
        setCandles((prev) => [...prev.filter((item) => `${item.id}` !== `${id}`)]);
        changeDeleteDialogBySetting('shareCandle', false);
      })
      .finally(() => setLoading(false));
  }

  // TributesAndCondolence
  function deleteTributesAndCondolence(id?: string) {
    if (!id) return;

    changeDeleteDialogBySetting('shareTributes', true, id, actionDeleteTributesAndCondolence);
  }

  function actionDeleteTributesAndCondolence(id?: string) {
    if (!editorData?.id || !id) return;

    setLoading(true);

    deleteTributesAndCondolences(editorData?.id, id)
      .then(() => {
        setTributesCandles((prev) => [...prev.filter((item) => item.id !== id)]);
        changeDeleteDialogBySetting('shareTributes', false);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.contentBox}>
            <ShowTributes
              tributes={tributesCandles}
              editable={editable}
              deleteTributesAndCondolence={deleteTributesAndCondolence}
            />
            <ShowCandles candles={candles} editable={editable} deleteCandle={deleteCandle} />
          </div>
          <div className={styles.creationBox}>
            <div className={styles.creationTributes}>
              <div className={styles.titleBox}>
                <h2>Add Tribute or Condolence </h2>{' '}
              </div>

              <div className={styles.tributeInputsBox}>
                <div className={`${styles.iconSelectBox} inputWithTitle`}>
                  <span>{translates?.userSettings?.provinceFieldLabel || 'Icon'}</span>
                  <Select
                    className={`${styles.iconSelect}`}
                    sx={{ backgroundColor: 'white' }}
                    value={editableRecord.icon}
                    onChange={({ target }) => {
                      setEditableRecord((r) => ({ ...r, icon: target.value }));
                    }}
                    error={actionData.shareTributes.errors.includes('icon')}
                    inputProps={{
                      sx: {
                        padding: mobile ? '11px 20px' : '23px 30px',
                        fontSize: mobile ? '18px' : '22px',
                        border: '1px solid black',
                      },
                    }}
                    MenuProps={{
                      className: 'selectRows',
                    }}
                    fullWidth
                  >
                    {sortedIcons.map((iconObj) => (
                      <MenuItem
                        key={iconObj.icon as string}
                        value={iconObj.icon as string}
                        sx={{
                          'display': 'inline-flex',
                          'borderBottom': '1px solid black',
                          'borderRight': '1px solid black',
                          'alignItems': 'center',
                          'justifyContent': 'center',
                          '&:nth-of-type(3n-2)': {
                            borderLeft: '1px solid black',
                          },
                          '&:nth-of-type(-n+3)': {
                            borderTop: '1px solid black',
                          },
                        }}
                      >
                        <img
                          className={styles.optionIcon}
                          src={iconObj.icon as string}
                          alt={iconObj.title as string}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={`${styles.nameTributes} inputWithTitle`}>
                  <span>{translates?.makeQuiltPage?.nameFieldLabel || 'Name'}</span>
                  <TextField
                    className="customInput"
                    value={editableRecord?.lastName || ''}
                    onChange={(e) => setEditableRecord((r) => ({ ...r, lastName: e.target.value }))}
                    variant="outlined"
                    placeholder="First and last name"
                  />
                </div>
              </div>

              <div className={styles.messageInput}>
                <div className="inputWithTitle">
                  <span>{translates?.makeQuiltPage?.nameFieldLabel || 'Message'}</span>
                  <TextField
                    className="customInput"
                    placeholder="Enter your message here."
                    fullWidth
                    multiline
                    rows={6}
                    value={editableRecord.message}
                    onChange={({ target }) => {
                      setEditableRecord((r) => ({
                        ...r,
                        message: target.value.slice(0, MAX_MESSAGE_LENGTH),
                      }));
                      setActionData((state) => {
                        return {
                          ...state,
                          shareTributes: {
                            ...actionData.shareTributes,
                            errors:
                              actionData.shareTributes.errors?.filter((e) => e !== 'message') || [],
                          },
                        };
                      });
                    }}
                    error={actionData.shareTributes.errors.includes('message')}
                  />
                </div>

                <div className={styles.actions}>
                  <button
                    onClick={() => {
                      setEditableRecord((r) => ({
                        ...r,
                        message: '',
                        firstName: '',
                        lastName: '',
                        id: '',
                        icon: 'none',
                      }));
                    }}
                    className={`customButton`}
                  >
                    Cancel
                  </button>

                  <CaptchaTooltip
                    disableHoverListener={true}
                    tooltipProps={{
                      open:
                        !actionData.shareTributes.errors.length &&
                        !actionData.shareTributes.captcha,
                    }}
                    setCaptcha={(captcha) => {
                      setActionData((state) => {
                        return {
                          ...state,
                          shareTributes: {
                            ...actionData.shareTributes,
                            captcha,
                          },
                        };
                      });
                    }}
                  >
                    <button className={`customButton`} onClick={(e) => shareTributes()}>
                      Share
                    </button>
                  </CaptchaTooltip>
                </div>
              </div>
            </div>
            <div className={styles.creationCandles}>
              <div className={styles.titleBox}>
                <h2>Light a Candle</h2>
              </div>

              <div className={styles.iconInput}>
                <div className="inputWithTitle">
                  <span>{translates?.makeQuiltPage?.nameFieldLabel || 'Name'}</span>
                  <TextField
                    className="customInput"
                    fullWidth
                    placeholder="First and last name"
                    value={lightCandleName || ''}
                    onChange={({ target }) => {
                      setLightCandleName(target.value);
                    }}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className={styles.candlesShare}>
                <button onClick={() => setLightCandleName('')} className={`customButton `}>
                  Cancel
                </button>
                <CaptchaTooltip
                  disableHoverListener={true}
                  tooltipProps={{
                    open: !actionData.shareCandle.errors.length && !actionData.shareCandle.captcha,
                  }}
                  setCaptcha={(captcha) => {
                    setActionData((state) => {
                      return {
                        ...state,
                        shareCandle: {
                          ...actionData.shareCandle,
                          captcha,
                        },
                      };
                    });
                  }}
                >
                  <button onClick={(e) => shareCandle()} className={`customButton`}>
                    Share
                  </button>
                </CaptchaTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      {getSettingDeleteModal && (
        <DefaultDialog
          open={getSettingDeleteModal.isOpen}
          setDialog={getSettingDeleteModal.close}
          title={getSettingDeleteModal.titleModal()}
          text=""
          cancelBtnText={'No'}
          confirmBtnText={'Yes'}
          confirmAction={getSettingDeleteModal.action}
        />
      )}

      <Loader loading={loading} />
    </>
  );
};

export default TributesAndCondolences;
