import { Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { DialogsName, hideDialog } from '../../store/reducers/dialogsSlice';
import { DialogBlocks } from '../CreateMemorialQuiltComponents/DialogBlocks';
import { DefaultDialog } from '../DefaultDialog';
import { setIsSave } from '../../store/reducers/notifySlice';
import { useRedirectAndScroll } from '../../hooks';
import { keySessionStorageSaveRoute } from '../../utilities';

export const Dialogs = () => {
  const blocksDialogState = useAppSelector((state) => state.dialogs.dialogsState.blocks);
  const saveDialogState = useAppSelector((state) => state.dialogs.dialogsState.save);
  const blocks = useAppSelector((state) => state.quilt.currentBlocks);
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);
  const dispatch = useAppDispatch();
  const { onlyRedirect } = useRedirectAndScroll({});

  const hideDialogBlocks = (name: DialogsName) => dispatch(hideDialog(name));

  const saveModalConfirm = () => {
    const route = sessionStorage.getItem(keySessionStorageSaveRoute);

    if (route) {
      dispatch(hideDialog('save'));
      dispatch(setIsSave(true));
      // forceNavigate because dispatch is asynchronous
      onlyRedirect(route, false, {
        forceNavigate: true,
      });
    } else {
      console.log('problems with save modal no route!!!');
    }
  };

  return (
    <>
      <Dialog fullScreen open={blocksDialogState} onClose={() => hideDialogBlocks('blocks')}>
        <DialogBlocks
          isTributes={editableQuilt?.isEnableTributesAndCondolence}
          blocksProps={blocks.length > 0 ? blocks : undefined}
        />
      </Dialog>

      <DefaultDialog
        open={saveDialogState}
        setDialog={() => hideDialogBlocks('save')}
        title={'Are you sure you want to exit?'}
        text={'All unsaved changes may be lost!'}
        cancelBtnText={'Stay'}
        confirmBtnText={'Exit'}
        confirmAction={saveModalConfirm}
      />
    </>
  );
};
