import { SortBy, UserSortField } from 'pages/AdminDashboard/UserTable/const';
import { httpClient } from '..';
import {
  AdminFetchQuery,
  AdminFetchQueryQuilts,
  AdminFetchQuiltsResponse,
  AdminFetchUsersResponse,
} from '../types';
import { QuiltSortField } from 'pages/AdminDashboard/QuiltTable/const';

export const fetchUsers = async ({
  filter = {},
  page = 1,
  limit = 10,
  getAdminStatus = false,
  includeData = false,
  sortBy = SortBy.ASC,
  sortField = UserSortField.EMAIL,
}: AdminFetchQuery): Promise<AdminFetchUsersResponse> => {
  const filters = `${filter?.email ? `email=${filter.email}&` : ''}${filter?.firstName ? `firstName=${filter.firstName}&` : ''}${filter?.lastName ? `lastName=${filter.lastName}` : ''}`;

  const data = await httpClient.get(
    `/administration?${filters}&page=${page}&limit=${limit}&getAdminStatus=${getAdminStatus}&includeData=${includeData}&sortBy=${sortBy}&sortField=${sortField}`
  );
  return data.data;
};

export const fetchQuilts = async ({
  firstName = '',
  lastName = '',
  page = 1,
  limit = 10,
  getAdminStatus = false,
  includeData = false,
  sortBy = SortBy.DESC,
  sortField = QuiltSortField.DATE_CREATED,
}: AdminFetchQueryQuilts): Promise<AdminFetchQuiltsResponse> => {
  const data = await httpClient.get(
    `/administration/quilts?firstName=${firstName}&lastName=${lastName}&page=${page}&limit=${limit}&getAdminStatus=${getAdminStatus}&includeData=${includeData}&sortBy=${sortBy}&sortField=${sortField}`
  );
  return data.data;
};

export const lockUser = async ({ userId, reason }: { userId: string; reason: string }) => {
  const data = await httpClient.post(`/administration/users/${userId}/lock?reason=${reason}`);
  return data;
};

export const unlockUser = async (userId: string) => {
  const data = await httpClient.post(`/administration/users/${userId}/unlock`);
  return data;
};

export const deleteUser = async ({ userId, reason }: { userId: string; reason: string }) => {
  const data = await httpClient.delete(`/administration/users/${userId}?reason=${reason}`);
  return data;
};

export const makeAdmin = async (userId: string) => {
  const data = await httpClient.post(`/administration/users/${userId}/make-admin`);
  return data;
};

export const revokeAdmin = async (userId: string) => {
  const data = await httpClient.post(`/administration/users/${userId}/revoke-admin`);
  return data;
};

export const lockQuilt = async ({ quiltId, reason }: { quiltId: string; reason: string }) => {
  const data = await httpClient.post(`/administration/quilts/${quiltId}/lock?reason=${reason}`);
  return data;
};

export const unlockQuilt = async (quiltId: string) => {
  const data = await httpClient.post(`/administration/quilts/${quiltId}/unlock`);
  return data;
};

export const deleteQuilt = async ({ quiltId, reason }: { quiltId: string; reason: string }) => {
  const data = await httpClient.delete(`/administration/quilts/${quiltId}?reason=${reason}`);
  return data;
};
