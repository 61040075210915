import { TArtificialElement } from './types';

export const getArtificialBlockSetting = (html: string): TArtificialElement => {
  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = html;
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = '800px';
  tempDiv.style.whiteSpace = 'normal';
  tempDiv.style.overflowWrap = 'break-word';

  document.body.appendChild(tempDiv);

  const height = tempDiv.offsetHeight;

  document.body.removeChild(tempDiv);

  return {
    height,
    element: tempDiv,
  };
};
