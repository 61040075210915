enum AccessPermissionsTextEnum {
  NO_EDIT = 'NoEdit',
  EDIT_IMAGES = 'EditImages',
  EDIT_IMAGES_MEMORIAL = 'EditImagesAndMemorial',
}

enum AccessPermissionsDisplayEnum {
  NoEdit = 'No Edit',
  EditImages = 'Edit Images',
  EditImagesAndMemorial = 'Edit Images And Memorial',
}

const optionsAddUsers = [
  { value: AccessPermissionsTextEnum.EDIT_IMAGES, label: 'Add images only' },
  { value: AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL, label: 'Add images and edit memorial' },
];

const optionsPermissions = [
  ...optionsAddUsers,
  { value: AccessPermissionsTextEnum.NO_EDIT, label: 'No editing Access' },
];

export type AccessPermissionsType = 'NoEdit' | 'EditImages' | 'EditImagesAndMemorial';

export {
  optionsAddUsers,
  optionsPermissions,
  AccessPermissionsTextEnum,
  AccessPermissionsDisplayEnum,
};
