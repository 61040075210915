import { FC, useEffect, useState } from 'react';

import { useAuth } from 'providers/auth-provider';
import { useAppDispatch, useRedirectAndScroll } from 'hooks';

import { Loader } from '../../components/Loader/Loader';
import { useParams, useSearchParams } from 'react-router-dom';
import { setOpen } from '../../store/reducers/notifySlice';
import { deleteCookie, setCookie } from '../../utilities';

interface RedirectToUpdateProps {}

const RedirectToUpdate: FC<RedirectToUpdateProps> = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { onlyRedirect } = useRedirectAndScroll({});
  const { login } = useAuth();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');

    if (!id || !token) {
      onlyRedirect('/login');
      return;
    }

    setLoading(true);

    login({
      token,
    })
      .then(() => {
        if (!id) return;

        setCookie('quiltId', id, 14);

        onlyRedirect(`/update/${id}?step=3`);
      })
      .catch((e) => {
        onlyRedirect('/login');
        deleteCookie('quiltId');

        if ('errors' in e) {
          const errors: string[] = e.errors;

          if (!errors.length) return;

          dispatch(setOpen(errors.join(', ')));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return <Loader loading={loading} />;
};

export default RedirectToUpdate;
