export const sizeColumnMyMemorials: Record<number, string> = {
  0: '120px',
  1: 'auto',
  2: '300px',
  3: '300px',
  4: '100px',
  5: '100px',
  6: '120px',
  7: '120px',
};

export const sizeColumnMyMemorialsTablet: Record<number, string> = {
  0: '100px',
  1: 'auto',
  2: 'auto',
  3: 'auto',
  4: '100px',
};

export const sizeColumnMyMemorialsMobile: Record<number, string> = {
  0: 'auto',
  1: '170px',
  2: '180px',
  3: 'auto',
};

export const sizeColumnInviteMemorials: Record<number, string> = {
  0: '100px',
  1: '40%',
  2: '40%',
  3: '100px',
  4: '100px',
  5: '100px',
};

export const sizeColumnInviteMemorialsMobile: Record<number, string> = {
  0: 'auto',
  1: '20%',
  2: '20%',
  3: '100px',
  4: '100px',
};

export const myTableHeaderFields = [
  '',
  'Quilt Name',
  'Display Preference',
  'Status',
  'Manage Quilts',
  '',
  '',
  '',
];

export const myTableHeaderFieldsTablet = ['', 'Quilt Name', 'Display Preference', 'Status', ''];

export const myTableHeaderFieldsMobile = ['Quilt Name', 'Display Preference', 'Status', ''];

export const tableHeaderFields = ['', 'Quilt Name', 'Status', '', ''];

export const tableHeaderFieldsMobile = ['Quilt Name', 'Status', ''];
