import React, { FC } from 'react';
import { CarouselItem } from '../CarouselItemType/CarouselItem';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import { CarouselItemType } from '../CarouselItemType/CarouselItemType';
import { CarouselItemType1 } from '../CarouselItemType/CarouselItemType1';
import { DataSettingModel } from '../../../../hooks/useCarouselHome';
import { QuiltsGroup } from 'common/types/quilts-group/quilts-group';

interface RowDeskPropsModel {
  indexOfRenderRow: number;
  items: QuiltsGroup[];
  settingData: DataSettingModel;
}

export const RowTablet: FC<RowDeskPropsModel> = ({ indexOfRenderRow, items }) => {
  return (
    <>
      {indexOfRenderRow % 2 === 0 ? (
        <CarouselItem>
          {items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <CarouselItemType isStub />
                {index % 2 === 0 ? (
                  <CarouselItemType item={item as QuiltsResponseMega} />
                ) : (
                  <CarouselItemType1 item={item as QuiltsResponseMega} />
                )}
              </React.Fragment>
            );
          })}
        </CarouselItem>
      ) : (
        <CarouselItem>
          {items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index % 2 !== 0 ? (
                  <CarouselItemType item={item as QuiltsResponseMega} />
                ) : (
                  <CarouselItemType1 item={item as QuiltsResponseMega} />
                )}
                <CarouselItemType isStub />
              </React.Fragment>
            );
          })}
        </CarouselItem>
      )}
    </>
  );
};
