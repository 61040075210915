import { v4 as uuidv4 } from 'uuid';
import { BlockModel } from './model';

enum TypeImageEnum {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  SQUARE = 'square',
}

const optionsTypeImages = [
  { value: TypeImageEnum.PORTRAIT, label: 'Portrait' },
  { value: TypeImageEnum.LANDSCAPE, label: 'Landscape' },
  { value: TypeImageEnum.SQUARE, label: 'Square' },
];

const aspectImageByType = {
  [TypeImageEnum.PORTRAIT]: 2 / 3,
  [TypeImageEnum.SQUARE]: 1 / 1,
  [TypeImageEnum.LANDSCAPE]: 16 / 9,
};

const styleImageByType = {
  [TypeImageEnum.PORTRAIT]: 'imagePortrait',
  [TypeImageEnum.SQUARE]: 'imageSquare',
  [TypeImageEnum.LANDSCAPE]: 'imageLandscape',
};

const defaultBlock: () => BlockModel = () => {
  return {
    id: uuidv4(),
    position: 'half',
    editorData: {},
  };
};

const initialStateBlocksData: BlockModel = {
  id: uuidv4(),
  position: 'header',
  topContent: {
    top: '',
  },
};

export {
  optionsTypeImages,
  TypeImageEnum,
  aspectImageByType,
  styleImageByType,
  defaultBlock,
  initialStateBlocksData,
};
