import { useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { searchQuilts } from 'api/http-client/queries/quiltQueries';
import { TSearchQuiltResponse } from 'api/http-client/types';
import { useTranslates } from 'providers/translates';
import { useAppDispatch, useRedirectAndScroll } from 'hooks';
import { notifyMessagesQuiltMemorialUpdateCreate } from 'utilities';
import { setOpen } from 'store/reducers/notifySlice';

import styles from './styles.module.scss';
import moment from 'moment';

interface DateInput {
  day: Date | null;
  month: Date | null;
  year: Date | null;
}

export type TQuiltSearch = {
  firstName: string;
  lastName: string;
  dateOfBirth: DateInput;
  dateOfPassing: DateInput;
  keywords: string[];
  location: string | null;
  quiltType: string;
};

const initialQuiltSearch = {
  firstName: '',
  lastName: '',
  dateOfBirth: {
    day: null,
    month: null,
    year: null,
  },
  dateOfPassing: {
    day: null,
    month: null,
    year: null,
  },
  keywords: [],
  location: null,
  quiltType: '',
};

// const PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

const SearchQuilt = () => {
  const { translates } = useTranslates();
  const transl = translates?.searchQuiltPage;
  const { redirectAndScroll } = useRedirectAndScroll({});
  const matches_900 = useMediaQuery('(max-width: 900px)');
  const matches_600 = useMediaQuery('(max-width: 600px)');
  const dispatch = useAppDispatch();

  const quiltsPerPage = matches_900 ? 8 : 16;

  const [quiltSearch, setQuiltSearch] = useState<TQuiltSearch>(initialQuiltSearch);
  const [foundQuilts, setFoundQuilts] = useState<TSearchQuiltResponse[] | null>([]);
  const [isLoading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [quiltPage, setQuiltPage] = useState(1);

  const searchClick = () => {
    if (
      (quiltSearch.dateOfBirth.day && !quiltSearch.dateOfBirth.month) ||
      (quiltSearch.dateOfPassing.day && !quiltSearch.dateOfPassing.month)
    ) {
      dispatch(
        setOpen(
          translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
            notifyMessagesQuiltMemorialUpdateCreate.validateDayWithoutMonth
        )
      );
      return;
    }
    if (
      (quiltSearch.dateOfBirth.month && !quiltSearch.dateOfBirth.year) ||
      (quiltSearch.dateOfPassing.month && !quiltSearch.dateOfPassing.year)
    ) {
      dispatch(
        setOpen(
          translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
            notifyMessagesQuiltMemorialUpdateCreate.validateMonthWithoutYear
        )
      );
      return;
    }

    setLoading(true);
    setFoundQuilts(null);
    searchQuilts({
      ...quiltSearch,
      dateOfBirth: quiltSearch.dateOfBirth.year
        ? {
            day: quiltSearch.dateOfBirth.day ? quiltSearch.dateOfBirth.day.getDate() : null,
            month: quiltSearch.dateOfBirth.month
              ? quiltSearch.dateOfBirth.month.getMonth() + 1
              : null,
            year: quiltSearch.dateOfBirth.year.getFullYear(),
          }
        : null,
      dateOfPassing: quiltSearch.dateOfPassing.year
        ? {
            day: quiltSearch.dateOfPassing.day ? quiltSearch.dateOfPassing.day.getDate() : null,
            month: quiltSearch.dateOfPassing.month
              ? quiltSearch.dateOfPassing.month.getMonth() + 1
              : null,
            year: quiltSearch.dateOfPassing.year.getFullYear(),
          }
        : null,
    })
      .then((foundQuiltsRes) => {
        if (foundQuiltsRes.length === 0) {
          setFoundQuilts(null);
          setNoResults(true);

          setTimeout(() => {
            setNoResults(false);
          }, 8000);
        } else {
          setFoundQuilts(foundQuiltsRes);
          setNoResults(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const visibleQuilts = useMemo(
    () =>
      foundQuilts?.slice(
        (quiltPage - 1) * quiltsPerPage,
        (quiltPage - 1) * quiltsPerPage + quiltsPerPage
      ),
    [foundQuilts, quiltPage, quiltsPerPage]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setQuiltPage(value);
  };

  return (
    <div style={{ backgroundColor: '#E2F4FB' }}>
      <div className={`${styles.search_quilt}`}>
        <div className={styles.search_title}>
          <h1 className="bigTitle" style={{ marginTop: 0 }}>
            {transl?.title || 'Search Memorial Quilt Squares'}
          </h1>
          <p className="defaultText colorGrey">
            {transl?.description ||
              'To search all public quilt squares, enter information below. To view a private quilt square, contact the creator for a direct link.'}
          </p>
        </div>
        <div className={styles.searchBox}>
          <h4 className="h4 defaultTitle">
            {transl?.searchFieldsHeader || 'Enter one or more pieces of information below:'}
          </h4>
          <div className={styles.inputBox}>
            <div className={styles.name_block}>
              <div className="inputWithTitle">
                <span>{transl?.firstName || 'First Name'}</span>
                <TextField
                  className="customInput"
                  size="small"
                  value={quiltSearch.firstName}
                  onChange={({ target }) =>
                    setQuiltSearch((s) => ({ ...s, firstName: target.value }))
                  }
                  type="search"
                />
              </div>

              <div className="inputWithTitle">
                <span>{transl?.lastName || 'Last Name'}</span>
                <TextField
                  className="customInput"
                  size="small"
                  value={quiltSearch.lastName}
                  onChange={({ target }) =>
                    setQuiltSearch((s) => ({ ...s, lastName: target.value }))
                  }
                  type="search"
                />
              </div>
            </div>

            <div className={styles.date_block}>
              <div className={styles.date_box}>
                <p className={styles.date_box__title}>Date of birth</p>
                <div className={styles.date_wrapper}>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd"
                        views={['day']}
                        value={quiltSearch.dateOfBirth.day}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfBirth: {
                              ...s.dateOfBirth,
                              day: date
                                ? s.dateOfBirth.year && s.dateOfBirth.month
                                  ? new Date(
                                      new Date(
                                        date.setFullYear(s.dateOfBirth.year.getFullYear())
                                      ).setMonth(s.dateOfBirth.month.getMonth())
                                    )
                                  : s.dateOfBirth.year
                                    ? new Date(date.setFullYear(s.dateOfBirth.year.getFullYear()))
                                    : s.dateOfBirth.month
                                      ? new Date(date.setMonth(s.dateOfBirth.month.getMonth()))
                                      : new Date(date)
                                : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MMM"
                        disableMaskedInput
                        views={['month']}
                        value={quiltSearch.dateOfBirth.month}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfBirth: {
                              ...s.dateOfBirth,
                              month: date
                                ? s.dateOfBirth.year
                                  ? new Date(date.setFullYear(s.dateOfBirth.year.getFullYear()))
                                  : new Date(date)
                                : null,
                              day:
                                s.dateOfBirth.day && date
                                  ? new Date(s.dateOfBirth.day.setMonth(date?.getMonth()))
                                  : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="yyyy"
                        views={['year']}
                        value={quiltSearch.dateOfBirth.year}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfBirth: {
                              ...s.dateOfBirth,
                              year: date ? new Date(date) : null,
                              day:
                                s.dateOfBirth.day && date
                                  ? new Date(s.dateOfBirth.day.setFullYear(date?.getFullYear()))
                                  : null,
                              month:
                                s.dateOfBirth.month && date
                                  ? new Date(s.dateOfBirth.month.setFullYear(date?.getFullYear()))
                                  : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                        maxDate={quiltSearch.dateOfPassing.year || new Date()}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <div className={styles.date_box}>
                <p className={styles.date_box__title}>Date of death</p>
                <div className={styles.date_wrapper}>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd"
                        views={['day']}
                        value={quiltSearch.dateOfPassing.day}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfPassing: {
                              ...s.dateOfPassing,
                              day: date
                                ? s.dateOfPassing.year && s.dateOfPassing.month
                                  ? new Date(
                                      new Date(
                                        date.setFullYear(s.dateOfPassing.year.getFullYear())
                                      ).setMonth(s.dateOfPassing.month.getMonth())
                                    )
                                  : s.dateOfPassing.year
                                    ? new Date(date.setFullYear(s.dateOfPassing.year.getFullYear()))
                                    : s.dateOfPassing.month
                                      ? new Date(date.setMonth(s.dateOfPassing.month.getMonth()))
                                      : new Date(date)
                                : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MMM"
                        disableMaskedInput
                        views={['month']}
                        value={quiltSearch.dateOfPassing.month}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfPassing: {
                              ...s.dateOfPassing,
                              month: date
                                ? s.dateOfPassing.year
                                  ? new Date(date.setFullYear(s.dateOfPassing.year.getFullYear()))
                                  : new Date(date)
                                : null,
                              day:
                                s.dateOfPassing.day && date
                                  ? new Date(s.dateOfPassing.day.setMonth(date?.getMonth()))
                                  : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputWithTitle">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="yyyy"
                        views={['year']}
                        value={quiltSearch.dateOfPassing.year}
                        onChange={(date: Date | null) =>
                          setQuiltSearch((s) => ({
                            ...s,
                            dateOfPassing: {
                              ...s.dateOfPassing,
                              year: date ? new Date(date) : null,
                              day:
                                s.dateOfPassing.day && date
                                  ? new Date(s.dateOfPassing.day.setFullYear(date?.getFullYear()))
                                  : null,
                              month:
                                s.dateOfPassing.month && date
                                  ? new Date(s.dateOfPassing.month.setFullYear(date?.getFullYear()))
                                  : null,
                            },
                          }))
                        }
                        renderInput={(params) => (
                          <TextField className="customInput customSvgIconDatePicker" {...params} />
                        )}
                        minDate={quiltSearch.dateOfBirth.year || undefined}
                        maxDate={new Date()}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Backend not ready yet */}
          {/* <div className={styles.inputBox}>
            <FormControl size="small">
              <InputLabel>{transl?.location || 'Province/Territory'}</InputLabel>
              <Select
                label={transl?.location || 'Province/Territory'}
                value={quiltSearch.location || 'ALL'}
                onChange={({ target }) => {
                  if (target.value === 'ALL') {
                    setQuiltSearch((s) => ({ ...s, location: null }));
                  } else {
                    setQuiltSearch((s) => ({ ...s, location: target.value }));
                  }
                }}
              >
                <MenuItem value="ALL">{transl?.allProvinces || 'All Provinces'}</MenuItem>
                {PROVINCES.map((province) => (
                  <MenuItem value={province}>{province}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label={transl?.quiltType || 'Type of Quilt'}
              size="small"
              value={quiltSearch.quiltType}
              onChange={({ target }) => setQuiltSearch((s) => ({ ...s, quiltType: target.value }))}
              type="search"
            />
          </div> */}

          <div className="inputWithTitle">
            <span>{transl?.keywords || 'Keyword(s)'}</span>
            <Autocomplete
              value={quiltSearch.keywords}
              onChange={(e, newValue: (string | string[])[]) => {
                setQuiltSearch((s) => ({ ...s, keywords: newValue as string[] }));
              }}
              multiple
              options={[]}
              freeSolo
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    sx={{ height: '28px', margin: '10px !important' }}
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="customInput"
                  placeholder="Type words to search for and press SEARCH"
                />
              )}
            />
          </div>

          <div className={styles.right}>
            <button
              className={`customButton ${styles.searchBtn}`}
              type="submit"
              onClick={searchClick}
              disabled={isLoading}
            >
              {transl?.searchBtn || 'Search'}{' '}
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          {isLoading && (
            <div className={styles.progress_box}>
              <CircularProgress color="primary" />
            </div>
          )}
          {noResults && (
            <p className="defaultText" style={{ textAlign: 'center' }}>
              No results. Please try a different filter.
            </p>
          )}
          {!!visibleQuilts?.length && (
            <div className={styles.foundBox}>
              {visibleQuilts.map((foundQuilt) => (
                <div className={styles.foundItem} key={foundQuilt.id}>
                  {foundQuilt.finalImage?.full ? (
                    <img src={foundQuilt.finalImage?.full} alt="QuiltImage" />
                  ) : (
                    <div className={styles.foundEmptyText}>No Image</div>
                  )}
                  <div>
                    <p>
                      <b>
                        {foundQuilt.firstName} {foundQuilt.lastName}
                      </b>
                    </p>
                    <p className={styles.foundItemDates}>
                      {moment(foundQuilt?.dateOfBirth).format('YYYY')}
                      {' - '}
                      {moment(foundQuilt?.dateOfPassing).format('YYYY')}
                    </p>
                    <div className={styles.foundLink}>
                      <Button
                        sx={{
                          width: 'fit-content',
                          color: 'white',
                          padding: '2px 8px',
                          paddingRight: '20px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          position: 'relative',
                          background: '#57a0c1',
                        }}
                        onClick={() => redirectAndScroll(`/quilts/${foundQuilt.id}/memorial`)}
                      >
                        <Typography
                          component="span"
                          sx={{
                            color: 'white',
                            fontWeight: 700,
                            marginTop: '2px',
                          }}
                        >
                          {translates?.carouselCard?.carouselCardBtnLabel || 'View'}
                        </Typography>
                        <ArrowRightIcon
                          sx={{ fontSize: '34px', position: 'absolute', right: '-6px' }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!!foundQuilts?.length && foundQuilts?.length > quiltsPerPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Pagination
                size={matches_600 ? 'medium' : 'large'}
                color="primary"
                page={quiltPage}
                siblingCount={matches_600 ? 0 : 1}
                onChange={handleChange}
                count={Math.ceil(foundQuilts?.length / quiltsPerPage)}
              />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchQuilt;
