import { FC, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import { showDialog } from 'store/reducers/dialogsSlice';
import {
  errorBuilderPrivateDialog,
  errorBuilderPublishDialog,
  successBuilderDialog,
  notifyMessages,
} from 'utilities';
import { useRedirectAndScroll, useAppDispatch, useAppSelector, useQuilt } from 'hooks/index';
import { Loader } from 'components/Loader/Loader';
import { DefaultDialog } from 'components/DefaultDialog';
import { setIsSave, setOpen } from 'store/reducers/notifySlice';

import styles from './styles.module.scss';
import { CreateMemorialSteps } from '../CreateMemorialSteps';

interface CreateMemorialQuiltStepFourProps {
  isActiveStep: number;
  steps: {
    index: number;
    title: string;
  }[];
}

export const CreateMemorialQuiltStepFour: FC<CreateMemorialQuiltStepFourProps> = ({
  isActiveStep,
  steps
}) => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const editableQuiltStore = useAppSelector((state) => state.quilt.editableQuilt);
  const { redirectAndScroll } = useRedirectAndScroll({});
  const { publish, loading } = useQuilt({ id: id || '' });

  const [typeMemorialPublish, setTypeMemorialPublish] = useState('public');
  const [errorPublishDialog, setErrorPublishDialog] = useState(false);
  const [errorPrivateDialog, setErrorPrivateDialog] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // need for correct moving from first step or third
    dispatch(setIsSave(true));
  }, [dispatch]);

  useEffect(() => {
    if (typeof editableQuiltStore?.isPublic !== 'boolean') return;

    setTypeMemorialPublish(editableQuiltStore?.isPublic ? 'public' : 'private');
  }, [editableQuiltStore]);

  useEffect(() => {
    if (!isMounted || !id) {
      setIsMounted(true);
      return;
    }

    if (typeMemorialPublish === 'public') {
      // setErrorPublishDialog(true);
    } else {
      setErrorPrivateDialog(true);
    }
  }, [typeMemorialPublish]);

  const showDialogBlocks = () => {
    dispatch(showDialog('blocks'));
  };

  const backAction = () => {
    redirectAndScroll(`${location.pathname}?step=3`);
  };

  const goToMyMemorial = () => {
    id && redirectAndScroll(`/quilts/${id}/memorial`);
  };

  const goToProfileUser = () => {
    redirectAndScroll(`/profile`);
  };

  const onPublish = () => {
    const isTypeMemorialPublish = typeMemorialPublish === 'public';
    const { finalImage } = editableQuiltStore;

    if (!finalImage && isTypeMemorialPublish) {
      dispatch(setOpen(notifyMessages.quiltPublicImageError));
      return;
    }

    publish.onPublish(isTypeMemorialPublish);
  };

  const handleViewing = () => {
    if (
      !!editableQuiltStore?.finalImage &&
      editableQuiltStore.isPublic &&
      editableQuiltStore.isPublished
    ) {
      redirectAndScroll(`/?visible=${id}`);
    } else {
      dispatch(setOpen(notifyMessages.quiltViewHomePageError));
    }
  };

  return (
    <div className={`center ${styles.block}`} id="fourStep">
      <div className={styles.settings}>
        <div className={styles.titleQuilt}>
          <span>Memorial quilt square </span>
          <span>
            {editableQuiltStore?.firstName || ''} {editableQuiltStore?.lastName || ''}
          </span>
        </div>
        <div className={styles.title}>
          <span className="defaultTitle">Display settings</span>
          <span className="defaultText">
            Read the descriptions below and choose how you want to share your memorial quilt square.
            You can make changes later in the “
            <span onClick={goToProfileUser} className={styles.settings__user}>
              My Quilts
            </span>
            ” section of “My Account.”
          </span>
        </div>

        <div>
          <RadioGroup
            defaultValue="public"
            onChange={(e) => setTypeMemorialPublish(e.target.value)}
            value={typeMemorialPublish}
          >
            <FormControlLabel
              sx={{ alignItems: 'start' }}
              value="public"
              control={<Radio />}
              componentsProps={{
                typography: {
                  color: '#333333',
                  fontSize: { xs: '18px', sm: '22px' },
                  fontFamily: 'Open Sans;',
                },
              }}
              label={
                <p>
                  <b>Share publicly on the Canadian Healing Quilt. </b>
                  <span>
                    You will receive a link (by email) that you can share with others. Visitors can
                    find it by searching by name. Anyone can see the photos and information you have
                    shared.
                  </span>
                </p>
              }
            />

            <FormControlLabel
              sx={{ alignItems: 'start' }}
              value="private"
              control={<Radio />}
              componentsProps={{
                typography: {
                  color: '#333333',
                  fontSize: { xs: '18px', sm: '22px' },
                  fontFamily: 'Open Sans;',
                },
              }}
              label={
                <p>
                  <b>Keep private. </b>
                  <span>
                    You will receive a link (by email) that you can share. Only people with the link
                    will be able to see the photos and information you have shared. Your quilt
                    square will NOT be visible or searchable in the Canadian Healing Quilt.
                  </span>
                </p>
              }
            />
          </RadioGroup>
        </div>

        <span className="defaultText">
          Click PUBLISH to save these settings and publish your quilt square.
        </span>

        <div className={styles.displayActions}>
          <button className={`customButton`} onClick={showDialogBlocks}>
            Preview
          </button>

          <div className={styles.displayActions__buttonText}>
            <span>I’m finished...</span>
            <button className={`customButton`} onClick={onPublish}>
              Publish
            </button>
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <span className="defaultTitle">
              How to share your memorial quilt square with others
            </span>
            <span className="defaultText">
              You can share on social media or by email using the icons on the right edge of your
              published memorial quilt square.
            </span>
          </div>

          <img src="/img/general/sharing-picture-1.png" alt="sharing-1" />
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <span className="defaultTitle">Manage tributes and condolences</span>
            <span className="defaultText">
              The tributes and condolences feature allows friends and family to add comments to your
              memorial quilt square. You can remove any unwanted posts by clicking on the garbage
              can next to the message. Note: you must be logged into your account to remove unwanted
              posts.
            </span>
          </div>

          <img className={styles.tributesImage} src="/img/general/tributesAndCondolences-screen-1.png" alt="sharing-1" />
        </div>

        <div className={styles.info} style={{ marginBottom: '40px' }}>
          <div className={styles.title}>
            <span className="defaultTitle">Manage preferences</span>
            <span className="defaultText">
              To update your profile information and see other quilts you can edit, go to "
              <span onClick={goToProfileUser} className={styles.settings__user}>
                My Account
              </span>{' '}
              .”
            </span>
          </div>
        </div>

        <div className="delimitierGrey"></div>

        <div className={styles.actions}>
          <div>
            <CreateMemorialSteps 
              isActiveStep={isActiveStep} 
              steps={steps} 
            />
          </div>
          <div className={styles.actions__right}>
            <button className={`customButton`} onClick={handleViewing}>
              View Quilt on Home Page
            </button>
            <button className={`customButton`} onClick={goToMyMemorial}>
              VIEW MY MEMORIAL
            </button>
          </div>
        </div>
      </div>

      <Loader loading={loading} />
      <DefaultDialog
        open={publish.successPublish}
        setDialog={publish.setSuccessPublish}
        title={successBuilderDialog.title}
        text={successBuilderDialog.text}
        noCancelBtn
        confirmBtnText="Close"
        confirmAction={() => publish.setSuccessPublish(false)}
      />
      <DefaultDialog
        open={errorPrivateDialog}
        setDialog={setErrorPrivateDialog}
        title={errorBuilderPrivateDialog.title}
        text={errorBuilderPrivateDialog.text}
        noCancelBtn
        confirmBtnText="OK"
        confirmAction={() => setErrorPrivateDialog(false)}
      />
      <DefaultDialog
        open={errorPublishDialog}
        setDialog={setErrorPublishDialog}
        title={errorBuilderPublishDialog.title}
        text={errorBuilderPublishDialog.text}
        noCancelBtn
        confirmBtnText="OK"
        confirmAction={() => setErrorPublishDialog(false)}
      />
    </div>
  );
};
