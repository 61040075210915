import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useWidthObserver = (selector: string, deps: any) => {
  const [frameWidth, setFrameWidth] = useState<number>();
  useEffect(() => {
    const config = {
      attributes: true,
    };
    const observerCallback: MutationCallback = function (mutationsList) {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          const elem = mutation.target as Element;
          setFrameWidth(elem.clientWidth);
        }
      }
    };
    const observer = new MutationObserver(observerCallback);
    const refElem = document.querySelector(selector);
    if (refElem) {
      observer.observe(refElem, config);
    }
    return () => observer.disconnect();
  }, [deps, selector]);
  return frameWidth;
};
