import { FC } from 'react';
import styles from './styles.module.scss';
import { CreateMemorialSteps } from '../CreateMemorialSteps';

interface CreateMemorialQuiltHeaderProps {
  title?: string;
  isActiveStep: number;
  steps: {
    index: number;
    title: string;
  }[];
  isShowInspiration?: boolean;
}

export const CreateMemorialQuiltHeader: FC<CreateMemorialQuiltHeaderProps> = ({
  title = 'Create a Memorial Quilt Square',
  isActiveStep,
  steps,
  isShowInspiration = true,
}) => {
  return (
    <div className={`center ${styles.header}`} id="createQuiltHeader">
      <span className={styles.title}>{title}</span>
      <div className={styles.header__items}>
        <CreateMemorialSteps 
            isActiveStep={isActiveStep}
            steps={steps}
        />
        {isShowInspiration && (
          <div className={styles.header__inspiration}>
            <span className={styles.header__inspirationText}>Need inspiration?</span>

            <button className={`button ${styles.header__inspirationButton}`}>
              <a href="/Healing-Quilt-Sample-Memorial.pdf" target="_blank">
                See examples
              </a>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
