import { FC } from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';

import { useAuth } from 'providers/auth-provider';
import { useRedirectAndScroll } from 'hooks';
import HomeCarousel from 'components/HomeCarousel';

import styles from './styles.module.scss';

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { redirectAndScroll } = useRedirectAndScroll({});
  const { isAuthorized } = useAuth();
  const mediaMax1350 = useMediaQuery('(max-width:1350px)');

  const handleRedirectHonour = () =>
    isAuthorized ? redirectAndScroll('/create?step=1') : redirectAndScroll('/signup');

  return (
    <Box
      style={{
        background: 'center / cover no-repeat url("/img/general/background-preview.png")',
        height: '100%',
      }}
      pb={8}
    >
      <HomeCarousel />

      <div className="center" style={{ marginBottom: '24px' }}>
        <Box mt={7} width={mediaMax1350 ? 'auto' : 850}>
          <span className={styles.title}>We are in a time of loss, and with loss, we grieve.</span>
        </Box>

        <Stack
          direction={mediaMax1350 ? 'column' : 'row'}
          gap={mediaMax1350 ? 10 : 0}
          justifyContent="space-between"
          mt={5}
        >
          <Box width={mediaMax1350 ? '100%' : '60%'}>
            <div className={styles.text}>
              <div>
                Part of what makes grief such an overwhelming emotion, is its profound dissonance.
                The physical being of your partner, your mother, your brother, your friend, your
                patient, is gone.
              </div>
              <div>
                An important part of this healing process can be memorialization. We see examples in
                every park in the country—memories rendered in stone and steel.
              </div>
              <div>Perhaps it’s a chance to say what was left unsaid.</div>
              <div>
                Each individual panel becomes a patch in a wider community quilt. The aim is to
                create a space for remembrance; a space where families, friends and communities can
                come together in caring and healing; a space that’s accessible and meaningful for
                all.
              </div>
              <div>No one who’s remembered is ever truly gone.</div>
            </div>
          </Box>

          <Stack
            spacing={9}
            justifyContent={mediaMax1350 ? 'center' : 'end'}
            alignItems={mediaMax1350 ? 'center' : ''}
          >
            <div className={styles.instruction} onClick={handleRedirectHonour}>
              <img
                className={styles.instructionImg}
                src="/img/general/dove-icon.png"
                alt="text-size"
                style={{
                  top: '-30px',
                }}
              />
              Honour someone with a Healing Quilt
            </div>

            <div className={styles.instruction} onClick={() => redirectAndScroll('/search')}>
              <img
                className={styles.instructionImg}
                src="/img/general/heart-icon.png"
                alt="text-size"
                style={{
                  top: '-22px',
                }}
              />
              Search for a person to Share a condolence, memory, light a candle
            </div>

            {/* <div className={styles.instruction}>
              <img className={styles.instructionImg} src="/img/general/candle-icon.png" alt="text-size" style={{
                top: '-50px'
              }}/>
              <Stack spacing={1} alignItems="center">
                <span>Send a whisper email</span>
                <img src="/img/general/question-icon.png" alt="text-size" width={24} height={24}/>
              </Stack>
            </div> */}

            <a href={'/resources'}>
              <div className={styles.instruction}>
                <img
                  className={styles.instructionImg}
                  src="/img/general/support-icon.png"
                  alt="text-size"
                  style={{
                    top: '-38px',
                    background: 'white',
                    borderRadius: '50%',
                  }}
                />
                Find support
              </div>
            </a>
          </Stack>
        </Stack>

        <div className={styles.textBottom}>
          <span>
            I am a poet...
            <br />
            Poets write on the breathe of a tree’s parched paper.
            <br />
            The tree is the ancestral knowledge keeper
            <br />
            Of all our relatives
            <br />
            Each branch extending to the universe
            <br />
            Breathing out prayer
            <br />
            Leaves open to the heavens.
            <br />
            This sapling
            <br />
            Is for those who’ve left,
            <br />
            For us still rooted to the earth.
            <br />
            –Louise Halfe
            <br />
          </span>
        </div>
      </div>
    </Box>
  );
};

export default Home;
