import { postPhotoToQuiltAlbum } from "api/http-client/queries/quilts";
import { ImageBodyModel } from "../CreateMemorialQuiltStepThree/model";
import { base64ToBlob } from "./base64-to-blob";

export const sendToCloud = async (quiltId: string, model: ImageBodyModel) => {
    const blob = base64ToBlob(model.image?.file as string);
    const newName = `edited-img-${model.image?.originalImage as string}`;
    const file = new File([blob], newName, { type: blob.type });
    const { returned: { full } } = await postPhotoToQuiltAlbum(quiltId as string, file);

    return full;
}