import React from 'react';
import { Box, TextField, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { emailRegex, httpMessages } from 'utilities';
import { TTranslate } from 'api/http-client/types';
import { forgetPassword } from 'api/http-client/queries/accounts';
import { Loader } from 'components/Loader/Loader';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch } from 'hooks';

export const ForgetPasswordForm: React.FC<{
  translates?: TTranslate;
  setDialog: () => void;
  isAdmin?: boolean;
  email?: string;
}> = ({ translates, setDialog, isAdmin, email }) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: email || '',
    },
  });

  const onSubmit = ({ email }: { email: string }) => {
    setLoading(true);
    forgetPassword(email).finally(() => {
      dispatch(
        setOpen(translates?.httpMessages?.sendEmailSuccess || httpMessages.sendEmailSuccess)
      );
      setLoading(false);
      setDialog();
    });
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 4 } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <span
            className="defaultTitle1"
            style={{
              textAlign: 'center',
            }}
          >
            {isAdmin
              ? 'Are you sure to reset user password?'
              : translates?.login?.resetPasswordTitle || 'Reset your password'}
          </span>

          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: emailRegex,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="inputWithTitle">
                <span>{translates?.login?.email || 'Email'}</span>
                <TextField
                  className="customInput"
                  error={!!error}
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim())}
                  helperText={
                    !!error
                      ? `${translates?.login?.emailHelperTextError || error.message || 'Wrong email'}`
                      : isAdmin
                        ? 'User will receive a password reset link'
                        : `${
                            translates?.login?.emailHelperTextSuccess ||
                            'We will send you a password reset link'
                          }`
                  }
                  FormHelperTextProps={{ style: { fontSize: 16 } }}
                />
              </div>
            )}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <button className="customButton" type="button" onClick={setDialog}>
              Cancel
            </button>
            <button className="customButton" type="submit">
              {translates?.login?.resetPasswordBtnLabel || 'Reset password'}
            </button>
          </Box>
        </Stack>
      </form>
      <Loader loading={loading} isWhite={false} />
    </Box>
  );
};
