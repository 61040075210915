import { QuiltsResponseMega } from 'api/http-client/types';
import { OptionsRowType, OptionsWrapEnum } from 'common/enums/carousel/carousel.enum';
import { QuiltsGroup } from 'common/types/quilts-group/quilts-group';

export const createSubArraysFromQuilts = (
  settings: { settingsRow: OptionsRowType[][]; option: OptionsWrapEnum },
  data: QuiltsResponseMega[]
) => {
  const total: QuiltsGroup[][] = [];
  let count = 0;

  while (count < data.length) {
    for (let i = 0; i < settings.settingsRow.length; i++) {
      const group: QuiltsGroup[] = [];

      for (let j = 0; j < settings.settingsRow[i].length; j++) {
        if (settings.settingsRow[i][j] === OptionsRowType.Stub) {
          group.push(null);
        } else {
          if (count < data.length) {
            group.push(data[count++]);
          } else {
            if (group.length !== 0) {
              total.push(group);
            }

            return total;
          }
        }
      }

      total.push(group);
    }
  }

  return total;
};
