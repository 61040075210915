import { ReactNode } from 'react';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SparkMD5 from 'spark-md5';

import { TDefaultListValue, TQuiltDateObject } from '../api/http-client/types';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const toFormatDate = (milliseconds: number, format: string) => {
  return moment(milliseconds).format(format);
};

export const isEmail = (email: string) => {
  return String(email).toLowerCase().match(EMAIL_REGEX);
};

export const getSelectOptions = (
  options: {
    value: string | number;
    label: string;
  }[],
  chooseValue: string
): ReactNode[] | null => {
  // first with 'display none' we need for select, it will understand value, second need for render list without choose value
  return [
    options.map((item) => {
      return (
        <MenuItem
          sx={{
            display: 'none',
          }}
          value={item.value}
        >
          {item.label}
        </MenuItem>
      );
    }),

    options.map((item) => {
      return +chooseValue !== +item.value ? (
        <MenuItem value={item.value}>{item.label}</MenuItem>
      ) : null;
    }),
  ];
};

export const getListWithIdByListArray = (list: string[]) => {
  return list.map((item) => {
    return {
      id: uuidv4(),
      value: item,
    };
  });
};

export const getArrayListWithoutId = (list: TDefaultListValue[]): string[] => {
  return [...list.map((item) => item.value || '')];
};

export const formatDate = (date: Date | null | undefined | string): string => {
  return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : '';
};

export const getFullDate = (dateObject: TQuiltDateObject | undefined | null): Date | null => {
  if (!dateObject) {
    return null;
  }
  const { day, month, year } = dateObject;

  if (day && month && year) {
    return new Date(year, month - 1, day);
  } else if (month && year) {
    return new Date(year, month - 1);
  } else if (year) {
    return new Date(year, 0);
  } else {
    return null;
  }
};

export const getFormattedDate = (dateObject: TQuiltDateObject | string | null): string => {
  if (!dateObject) {
    return '';
  }
  if (typeof dateObject === 'string') {
    return moment(dateObject).format('MMMM DD, YYYY');
  } else if (dateObject.day && dateObject.month && dateObject.year) {
    return moment(getFullDate(dateObject)).format('MMMM DD, YYYY');
  } else if (dateObject.month && dateObject.year) {
    return moment(getFullDate(dateObject)).format('MMMM YYYY');
  } else if (dateObject.year) {
    return moment(getFullDate(dateObject)).format('YYYY');
  } else {
    return '';
  }
};

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as never);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });
};

export const compareFiles = (file1: File, file2: File): Promise<boolean> => {
  return new Promise(async (res) => {
    const [arrayBuffer1, arrayBuffer2] = await Promise.all([
      readFileAsArrayBuffer(file1),
      readFileAsArrayBuffer(file2),
    ]);

    const hash1 = SparkMD5.ArrayBuffer.hash(arrayBuffer1);
    const hash2 = SparkMD5.ArrayBuffer.hash(arrayBuffer2);

    res(hash1 === hash2);
  });
};

export async function downloadImage(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const fileName = url.split('/').pop() || '';

  return new File([blob], fileName, { type: blob.type });
}

export const createFileFromUrl = async (url: string, filename: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  const file = new File([blob], filename, { type: blob.type });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);

  link.setAttribute('download', filename);

  // link.click();

  URL.revokeObjectURL(link.href);

  return file;
};

export const getName = (obj: { lastName?: string; firstName?: string }) => {
  return `${obj?.firstName || ''} ${obj?.lastName || ''}`;
};

export function deepEqual(object1: any, object2: any, ignoreKeys?: string[]): boolean {
  if (object1 === object2) return true;

  if (
    typeof object1 !== 'object' ||
    typeof object2 !== 'object' ||
    object1 === null ||
    object2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  for (let key of keys1) {
    if (ignoreKeys && ignoreKeys.includes(key)) {
      continue;
    }

    if (!keys2.includes(key)) return false;
    if (!deepEqual(object1[key], object2[key], ignoreKeys)) return false;
  }

  return true;
}

export function openLinkInNewWindow(url: string) {
  const windowName = '_blank';
  const windowFeatures = 'width=800,height=600';

  window.open(url, windowName, windowFeatures);
}

export function setCookie(name: string, value: string, days: number) {
  const date = new Date();

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const expires = '; expires=' + date.toUTCString();

  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) == ' ') c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Max-Age=0; path=/;';
}

export function downloadPDF(url: string, name: string = 'file') {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `${name}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error downloading the PDF:', error);
    });
}
