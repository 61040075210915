import React from 'react';

import { TProfile } from 'api/http-client/profileTypes';
import { useRedirectAndScroll } from 'hooks';

import styles from './styles.module.scss';

export const Welcome: React.FC<{ user: TProfile | null }> = ({ user }) => {
  const { redirectAndScroll } = useRedirectAndScroll({});

  return (
    <section className={styles.welcome_container}>
      <div className={styles.text_wrapper}>
        <h2 className={styles.tab_title}>Welcome {user?.firstName}.</h2>
        <p className={styles.welcome_text}>
          Thank you for creating a memorial quilt square to honour someone who has died and
          contribute to a national quilt of honouring, remembering, and healing.
        </p>
        <p className={styles.welcome_text}>
          As a first step, fill in the person’s name and invite others you’d like to help create the
          memorial.
        </p>
        <p className={styles.welcome_text}>Next add details, photos, memories, and more.</p>
        <p className={styles.welcome_text}>
          When your quilt square is finished, it can be shared publicly as part of the Canadian
          Healing Quilt or privately with those you choose.
        </p>
        <button
          className={`customButton ${styles.start_button}`}
          onClick={() => redirectAndScroll('/create?step=1')}
        >
          Start Now
        </button>
      </div>
      <img className={styles.image} src="/img/general/person-6-border.png" alt="Resources" />
    </section>
  );
};
